import React, { useState, useEffect } from "react";
import "./App.css";
import { Route, BrowserRouter, Routes, HashRouter } from "react-router-dom";
import { Layout, ConfigProvider } from "antd";
import enUS from "antd/lib/locale-provider/en_US";
import { useJwt, decodeToken } from "react-jwt";
import WelcomeScreen from "./Screens/WelcomeScreen";
import { Nav } from "./Nav";
import { UserContext } from "./globalContext";

import { Footers } from "./Footers"; 
import LoginScreen from "./Screens/LoginScreen";
import SignupScreen from "./Screens/SignupScreen";
import HomeScreen from "./Screens/HomeScreen";
import CustomerAddScreen from "./Screens/Customer/CustomerAddScreen";
import CustomerListScreen from "./Screens/Customer/CustomerListScreen";
import MemberListScreen from "./Screens/Member/MemberListScreen";

import MemberEditScreen from "./Screens/Member/MemberEditScreen";
import CustomerEditScreen from "./Screens/Customer/CustomerEditScreen";
import FatsnfRateMatrix from "./Screens/FatsnfRateMatrix/FatsnfRateMatrix";
import CompanyScreen from "./Screens/Company/CompanyScreen";
import CompanyEditScreen from "./Screens/Company/CompanyEditScreen";
import FatScreen from "./Screens/Fat/FatScreen";
import SnfScreen from "./Screens/Snf/SnfScreen";
import RoleScreen from "./Screens/Role/RoleScreen";
import VehicleTypeScreen from "./Screens/Vehicle/VehicleTypeScreen";
import VehicleScreen from "./Screens/Vehicle/VehicleScreen";
import RouteScreen from "./Screens/Route/RouteScreen";
import UserRoleScreen from "./Screens/Role/UserRoleScreen";
import DefaultUserSetting from "./Screens/DefaultUserSetting/DefaultUserSetting";
import PickupListScreen from "./Screens/Pickup/PickupListScreen";
import PickupAddScreen from "./Screens/Pickup/PickupAddScreen";
import PickupEditScreen from "./Screens/Pickup/PickupEditScreen";
import MyAccount from "./Screens/MyAccount/MyAccount";
import ForgotPassword from "./Screens/ForgotPassword/ForgotPassword";
import RouteEditScreen from "./Screens/Route/RouteEditScreen";
import Pdf from './pdf';
import CollactionSummerys from "./Screens/Pickup/collactionsummery";
import SCustomerAddScreen from "./Screens/SCustomer/SCustomerAddScreen";
import SCustomerListScreen from "./Screens/SCustomer/SCustomerListScreen";
import SalsAddScreen from "./Screens/Sales/AddSales";
import SalesListScreen from "./Screens/Sales/SaleListScreen";
import Invoice01 from "./Screens/InvoicePdf/Invoice01";
import MonthelyCollaction from "./Screens/Pickup/MonthSummery";
import SalseCollactionSummery from "./Screens/Sales/SalesSummery";
import { PrintList } from "./Screens/InvoicePdf/dummy";
import SCustomerEditScreen from "./Screens/SCustomer/SCustomerEditScreen";
import Payment from "./Screens/payment/payment";
import VehicleEditScreen from "./Screens/Vehicle/VehicleEditScreen";
import VehicleTypeEditScreen from "./Screens/Vehicle/VehicleTypeEditScreen";
import EmployeeAddSecreen from "./Screens/Member/MemberAddScreen";
import AddFeedback from "./Screens/feedback/Addfeedback";
import FeedbackListScreen from "./Screens/feedback/Feedback";
import Packages from "./Screens/Packages/Packages";
import Notifiction from "./Screens/Notification/notification";
import DeletedMemberList from "./Screens/Admin/DeletedMemberList";
import Support from "./Screens/Support/Support";
import ActiveCustomer from "./Screens/Admin/ActiveCustomer";
import DeletedCustomer from "./Screens/Admin/DeletedCustomer";
import DeletedSCustomer from "./Screens/Admin/DeletedSCustomer";
import ActiveSCustomer from "./Screens/Admin/ActiveSCustomer";
import MemberListAdmin from "./Screens/Admin/ActiveMember";
import AllPickupListScreen from "./Screens/Admin/AllPickupList";
import AllSalesListScreen from "./Screens/Admin/AllSalesList";

require("./css/App-dev.css");
require("./css/responsive.css");
require("./Screens/login.css");

function App() {
  const [waitstate, setWaitstate] = useState(true);
  const [authToken, setAuthToken] = useState(null);
  // const { authToken, isExpired } = useJwt(JSON.parse(localStorage.getItem("authtoken")));

  useEffect(() => {

    if (
      localStorage.getItem("authtoken") !== null &&
      localStorage.getItem("authtoken") !== undefined
    ) {

      const myDecodedToken = decodeToken(JSON.parse(localStorage.getItem("authtoken")));
if(myDecodedToken.role===undefined){
  myDecodedToken.role="test"
  setAuthToken(myDecodedToken);
}
else{
  setAuthToken(myDecodedToken);
}
     
      setWaitstate(false);
      localStorage.setItem("username", JSON.parse(localStorage.getItem("authtoken")).name)
    } else {
      setWaitstate(false);
      setAuthToken(null);
     // window.location.href = "/";
      //navigate("/LoginScreen", { replace: true });

    }
  }, []);
  const validation = () => {

    const myDecodedToken = decodeToken(JSON.parse(localStorage.getItem("authtoken")));
    setAuthToken(myDecodedToken);
  }


  return (
    <HashRouter>
      <div className="App">
        {!waitstate && (
          <UserContext.Provider
            value={
              authToken !== null
                ? {
                  userId: authToken._id === undefined ? 1 : authToken._id,
                  companyId: authToken.companyId === undefined ? 1 : authToken.companyId._id,
                  companyName: authToken.companyId === undefined ? 1 : authToken.companyId.name,
                  memberId: authToken.companyId === undefined ? 1 : authToken._id,
                  role: authToken.role === undefined ? 'Employee' : authToken.role.name,
                  roleId: authToken.role === undefined ? 'Employee' : authToken.role._id,
                  route: authToken.route === undefined ? 1 : authToken.route,
                  registerDate: authToken.registerDate === undefined ? 1 : authToken.registerDate,
                  config: {
                    dateFormat: "DD-MMM-YYYY",
                    datetimeFormat: "DD-MMM-YYYY HH:mm",
                    datetimeFormatWithoutYear: "DD-MMM HH:mm",
                    datetimeSecondFormat: "DD-MMM-YYYY HH:mm:ss",
                    datetimeSecondFormatAMPM: "DD-MMM-YYYY hh:mm:ss A",
                    timeFormat: "hh:mm:ss A",
                  }
                }
                : {
                  userId: null,

                  config: {
                    dateFormat: "DD-MMM-YYYY",
                    datetimeFormat: "DD-MMM-YYYY HH:mm",
                    datetimeFormatWithoutYear: "DD-MMM HH:mm",
                    datetimeSecondFormat: "DD-MMM-YYYY HH:mm:ss",
                    datetimeSecondFormatAMPM: "DD-MMM-YYYY hh:mm:ss A",
                    timeFormat: "hh:mm:ss A",
                  }
                }
            }
          >
            <Layout className="layout">
              {(authToken && authToken.id !== undefined) && (authToken && authToken.id !== null) && <Nav authToken={authToken} />}
              <ConfigProvider locale={enUS}>
                <Routes>
                  <Route path="/" element={<LoginScreen />} />
                  <Route path="LoginScreen" element={<LoginScreen />} />
                  <Route path="SignupScreen" element={<SignupScreen authToken={authToken} />} />
                  <Route path="AddEmployee" element={<EmployeeAddSecreen />} />
                  <Route path="collactionSummery" element={<CollactionSummerys />} />
                  <Route path="MonthelyCollaction" element={<MonthelyCollaction />} />
                  <Route path="SalseCollactionSummery" element={<SalseCollactionSummery />} />

                  {/* <Route path="Dummy" element={<PrintList/>}/> */}

                  <Route
                    path="MemberEditScreen/:id"
                    element={<MemberEditScreen />}
                  />
                  <Route path="HomeScreen" element={<HomeScreen ppp={true} />} />
                  <Route
                    path="CustomerEditScreen/:id"
                    element={<CustomerEditScreen />}
                  />
                  <Route
                    path="CustomerAddScreen"
                    element={<CustomerAddScreen />}
                  />
                  <Route
                    path="CustomerListScreen"
                    element={<CustomerListScreen />}
                  />
                  <Route
                    path="MemberListScreen"
                    element={<MemberListScreen />}
                  />
                  <Route
                    path="FatsnfRateMatrix"
                    element={<FatsnfRateMatrix />}
                  />
                  <Route path="CompanyScreen" element={<CompanyScreen />} />
                  <Route
                    path="ComapnyEditScreen/:id"
                    element={<CompanyEditScreen />}
                  />
                  <Route path="FatScreen" element={<FatScreen />} />
                  <Route path="Export" element={<Pdf />} />
                  <Route path="SnfScreen" element={<SnfScreen />} />
                  <Route path="RoleScreen" element={<RoleScreen />} />
                  <Route path="VehicleScreen" element={<VehicleScreen />} />
                  <Route path="VehicleEditScreen/:id" element={<VehicleEditScreen />} />
                  <Route path="VehicleTypeEditScreen/:id" element={<VehicleTypeEditScreen />} />
                  <Route path="SCustomerEditScreen/:id" element={<SCustomerEditScreen />} />
                  <Route path="ActiveCustomer" element={<ActiveCustomer/>} />
                  <Route path="DeletedCustomer" element={<DeletedCustomer/>} />
                  <Route path="DeletedSCustomer" element={<DeletedSCustomer/>} />
                  <Route path="ActiveSCustomer" element={<ActiveSCustomer/>} />
                  <Route path="AllPickup" element={<AllPickupListScreen/>} />
                  <Route path="AllSales" element={<AllSalesListScreen/>} />
                  <Route
                    path="VehicleTypeScreen"
                    element={<VehicleTypeScreen />}
                  />
                  <Route path="RouteScreen" element={<RouteScreen />} />
                  <Route path="MemberListScreenAdmin" element={<MemberListAdmin />} />
                  <Route path="DeletedMember" element={<DeletedMemberList/>} />
                  <Route path="Support" element={<Support/>} />
                  <Route path="UserRole" element={<UserRoleScreen />} />
                  <Route path="SCustomer" element={<SCustomerAddScreen />} />
                  <Route path="SCustomerList" element={<SCustomerListScreen />} />
                  <Route path="AddSales" element={<SalsAddScreen />} />
                  <Route path="SelsList" element={<SalesListScreen />} />
                  <Route path="Invoice" element={<Invoice01 />} />
                  <Route path="Payment" element={<Payment />} />
                  <Route path="Feedback" element={<AddFeedback />} />
                  <Route path="FeedbackList" element={<FeedbackListScreen />} />
                  <Route path="Packages" element={<Packages/>} />
                  <Route path="Notification" element={<Notifiction/>} />
                  <Route
                    path="DefaultUserSetting"
                    element={<DefaultUserSetting />}
                  />
                  <Route
                    path="PickupListScreen"
                    element={<PickupListScreen />}
                  />
                  <Route path="PickupAddScreen" element={<PickupAddScreen />} />
                  <Route
                    path="PickupEditScreen/:id"
                    element={<PickupEditScreen />}
                  />
                  <Route path="MyAccount" element={<MyAccount />} />
                  <Route path="ForgotPassword" element={<ForgotPassword />} />
                  <Route
                    path="RouteEditScreen/:id"
                    element={<RouteEditScreen />}
                  />
                </Routes>
              </ConfigProvider>
              <Footers />
            </Layout>
          </UserContext.Provider>
        )}
      </div>
    </HashRouter>
  );
}

export default App;
