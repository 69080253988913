import React, { useContext, useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
    Table,
    Modal
  } from "antd";
import { UserContext } from "../../globalContext";
import moment from "moment";

const Invoice01 = (props) =>{
    
  const user = useContext(UserContext);
  let defaultDate =new Date();
 
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);

  const pageLoad = () => {
    setCompanyName(user.companyName);

  }
const GenerateInvoice = () => {
        html2canvas(document.querySelector("#invoiceCapture")).then((canvas) => {
          const imgData = canvas.toDataURL("image/png", 1.0);
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "pt",
            format: [595,842],
          });
          pdf.internal.scaleFactor = 1;
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          pdf.save("invoice-001.pdf");
        });
      }



      const columns1 = [
              
        {
          title: "Milk Type",
          dataIndex: "milkType",
          key: "milkType",
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          key: "quantity",
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
        },
    
       
      ]
      var AmountTotal = 0;
      var quantityTotal = 0;
      var TotalQuantity = 0;
      var TotalAmount = 0;
       
      for (let i = 0; i < props.pickupService.length; i++) { 
        AmountTotal = props.pickupService[i].amount;
        quantityTotal = props.pickupService[i].quantity;
        TotalAmount = TotalAmount+AmountTotal ;
        TotalQuantity = TotalQuantity +quantityTotal ;
      }

const onClncle= ()=>{
    props.setIsModalOpen1(false)
}
const sectionRef = useRef(null);

const captureAndDownload = () => {
  if (sectionRef.current) {
    html2canvas(sectionRef.current).then(canvas => {
      const screenshotUrl = canvas.toDataURL(); // This will contain the screenshot image data

      const link = document.createElement('a');
      link.href = screenshotUrl;
      link.download = 'screenshot.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
}
return(
<div>
<Modal
       className="mymodal"
        open={props.isVisible}
        onCancel={onClncle}
        footer={
            <div>
            <div className="container">
                <div className="d-flex justify-content-end">
                <Button className="btnmyraj" onClick={onClncle}>Cancel</Button>
                <Button className=" btnmyraj01" onClick={GenerateInvoice}>Download</Button>
                </div>
            </div>
                
            </div>
           
        }>

      
         <div>

         <div>
    
      <div href={sectionRef}>
      <div id="invoiceCapture">
            <page size="A4">
            <div className="bg-light">
            <div className="container">
            <div className="row align-items-center">
            <div className="col-md-4 hemop">
            <h6 className="fw-bold text-secondary">
                  Invoice #:{ props.Customer.id}
            </h6>
            </div>
              <div className="col-md-4 raj">
                <h4 className="">
                  {companyName}
                </h4>
                
              </div>
              <div className="col-md-4 d-flex align-items-center text-center ">
                <h6 className="ret">Amount&nbsp;Due:</h6>
                <h5 className="fw-bold text-secondary">
                  {TotalAmount.toFixed(2)}
                </h5>
              </div>
            </div>
           
           </div>
            </div>

            <div className="container">
            <div className="p-1" >
              <div className="row mb-4">
                <div className="col-md-4">
           <div className="d-flex mt-2">
           <div className="fw-bold " style={{marginRight:"8px", }}>Customer details:  </div>
              <div>
              <div>{props.Customer.name}</div>
                  <div>{props.Customer.city}</div>
                  <div>{props.Customer.mobile}</div>
              </div>
           </div>
                 
                </div>
               
                <div className="col-md-4">
                 <div className="d-flex mt-2">
                 <div className="fw-bold " style={{marginRight:"8px",}}>Date Of Issue:</div>
                  <div>{moment(defaultDate).format("DD-MM-yyyy")}</div>
                 </div>
                </div>
              </div>
              </div>


              
              <div style={{ overflow: "auto" }}>
        <Table  key="id" columns={columns1} dataSource={props.pickupService} pagination={false} />
      </div>

      <div className="mt-5">
          <div className="text-end ">

<div className="fw-bold "style={{marginRight:"8px",}} >
  TOTAL QUANTITY :
</div>
<div className="fw-bold " >
  {TotalQuantity}
</div>
</div>
<div className="text-end mt-2">
<div className="fw-bold " style={{marginRight:"8px",}}>
    TOTAL AMOUNT :
    </div>
    <div className="fw-bold " >
  {TotalAmount.toFixed(2)}
  </div>
  </div>
          </div>
            </div>
             



















            


       
           <div className="container-fluid text-center">
           <div className="bg-light py-3 px-4 rounded">
                  Enjoy Your Life
                </div>
           </div>
             
            </page>
           
          </div>
      </div>
    </div>

       

       
         </div>
        </Modal>

       {/* <Modal
        open={props.isVisible}
        onCancel={onClncle}
        width={"96vw"}
        footer={
            <div>
            <Row>
                <Col>
                <Button onClick={onClncle}>Cancle</Button>
                <Button 
                variant="primary"
                  className=""
                  onClick={GenerateInvoice}>Download</Button>
                </Col>
            </Row>
                
            </div>
           
        }>

      
         <div>
         <div id="invoiceCapture">
            <div className="row bg-light align-items-center p-3">
            <div className="col-md-4">
            <h6 className="fw-bold text-secondary mb-1">
                  Invoice #: 01
                </h6>
            </div>
              <div className="col-md-4">
                <h4 className="">
                  {companyName}
                </h4>
                
              </div>
              <div className="col-md-4 d-flex m-300">
                <h6 className="ret">Amount&nbsp;Due:</h6>
                <h5 className="fw-bold text-secondary">
                  {TotalAmount}
                </h5>
              </div>
            </div>
            <div className="p-4" >
              <Row className="mb-4">
                <Col md={4}>
                  <div className="fw-bold">Billed to:</div>
                  <div>{props.Customer.name}</div>
                  <div>{props.Customer.city}</div>
                  <div>{props.Customer.email}</div>
                 
                </Col>
                <Col md={4}>
                  <div className="fw-bold">Billed From:</div>
                  <div>{props.member.name}</div>
                  <div>{props.member.city}</div>
                  <div>{props.member.email}</div>
                </Col>
                <Col md={4}>
                  <div className="fw-bold mt-2">Date Of Issue:</div>
                  <div>{moment(defaultDate).format("DD-MM-yyyy")}</div>
                </Col>
              </Row>
              </div>

              <div style={{ overflow: "auto" }}>
        <Table style={{marginLeft:"7vw",marginRight:"4vw"}} key="id" columns={columns1} dataSource={props.pickupService} pagination={false} />
      </div>

                    <div className="text-end " style={{marginTop:"2vh",marginRight:"15vw"}}>

                    <div className="fw-bold " style={{ width: "100px"}}>
                      TOTALQUANTITY
                    </div>
                    <div className="fw-bold " style={{ width: "100px"}}>
                      {TotalQuantity}
                    </div>
                    </div>
                    <div className="text-end" style={{marginRight:"15vw",marginTop:"2vh"}}>
                    <div className="fw-bold " style={{ width: "100px"}}>
                        TOTALAMOUNT
                        </div>
                        <div className="fw-bold " style={{ width: "100px"}}>
                      {TotalAmount}
                      </div>
                      </div>
                <div className="bg-light py-3 px-4 rounded">
                  Enjoy Your Life
                </div>
             
           
          </div>
         </div>
        </Modal> */}
      </div>
)



};
export default Invoice01;