import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Modal,
  message,
  Form,
  Row,
  Col,
  Button,
  DatePicker,
  Space,
  Input,
} from "antd";

import { PageTitle } from "../../PageTitle";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

const CollactionSummerys = () => {
  const user = useContext(UserContext);
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  let defaultOldDate = new Date();
  defaultOldDate.setDate(defaultOldDate.getDate() - 30);

  const [pickupService, setPickupService] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [readPickupObj, setReadPickupObj] = useState({});
  const [fromDate, setFromDate] = useState(defaultOldDate);
  const [toDate, setToDate] = useState(defaultDate);
  const [Amount, setAmount] = useState(defaultDate);
  

  useEffect(() => {
    let mounted = true;
    if (mounted) getPickup();

    return () => (mounted = false);
  }, []);
  // {pickupService && pickupService.map(values => {
  // let ageSum = 0
  // for (let i = 0; i <= pickupService.length; i++) {
  //   ageSum += pickupService[i].age
  //   
  // }
  // setAmount(ageSum)})}

  const columns1 = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
     
        {
            title: "Customer",
            render: (row) => {
              return row.customer ? (
                <div>
                { row&& <>{row.customer.name}</>}
                  </div>
              ) : (
                <> </>
              );
            },
      
          },

    {
      title: "Snf",
      key: "snf",
      render: (row) => {
        return (
          <>
            <label>{row.snf}</label>

          </>
        );
      },
    },
    {
      title: "Fat",
      dataIndex: "fat",
      key: "fat",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Milk Type",
      dataIndex: "milkType",
      key: "milkType",
    },
    {
        title: "Vehicle",
        render: (row) => {
          return row.vehicle ? (
            <div>
            { row&& <>{row.vehicle.name}</>}
              </div>
          ) : (
            <> </>
          );
        },
    },


    {
      title: "Route",
      render: (row) => {
        return row.routeId ? (
          <div>
          { row&& <>{row.routeId.name}</>}
            </div>
        ) : (
          <> </>
        );
      },
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      // render: (transactionDate) => {
      //   return <div>{moment(transactionDate).format("DD-MMM-yyyy")}</div>;
      // },
    },
  //   {
  //     header: 'Amount',
  //    footer: () => {

  //   let ageSum = 0
  //   for (let i = 0; i <= pickupService.length; i++) {
  //     ageSum += pickupService[i].amount
  //   }
  //   return 'Total: ' + ageSum
  // }
  //   },
  // {
  //   title: 'Age',
  //   footer: 'Total: ' 
  // }
  ];

  const getPickup = () => {
    axiosInstance.get(`/pickup/user/${user.companyId}`).then((res) => {
      setPickupService(res.data.data);
    });
  };
  const onGo = () => {
    axiosInstance
      .post(`/pickup/user/${user.companyId}`, {
        fromDate: fromDate,
        toDate: toDate,
      })
      .then((res) => {
        if (res.data && res.data.responseCode === -1) {
          message.error("Failed! please contact administor.");
        } else if (res.data && res.data.responseCode === 1) {
          message.success("Filter applied successfully");
          setPickupService(res.data.data);
        } else message.error("Something wrong. Please try again...!");
      });
  };
  const onFromChange = (date, dateString) => {
    setFromDate(date, dateString);
  };
  const onToChange = (date, dateString) => {
    setToDate(date, dateString);
  };

  const dateFormat = "DD/MM/YYYY";
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Pickup report";
    const headers =  [["ID","Name", "Fat","Snf","Rate","Quantity","Amount","Route Name",'Milk Type',]]

    const data = pickupService.map(elt=> [elt.id,elt.customer.name, elt.fat,elt.snf,elt.rate,elt.quantity,elt.amount,elt.routeId.name,elt.milkType]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Pickuplist.pdf")
  }
  var AmountTotal = 0;
  var quantityTotal = 0;
  var TotalQuantity = 0;
  var TotalAmount = 0;
   
  for (let i = 0; i < pickupService.length; i++) { 
    AmountTotal = pickupService[i].amount;
    quantityTotal = pickupService[i].quantity;
    TotalAmount = TotalAmount+AmountTotal ;
    TotalQuantity = TotalQuantity +quantityTotal ;
  }
  return (
    <div className="collaction-summery-list">
      <div>
        <Row>
          <Col lg={10} md={16} xs={18}>
            <PageTitle title="Collaction Summery">
            </PageTitle>
          </Col>
        </Row>

        <Form
          name="basic"
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 16,
          }}
        >
          <Row gutter={3}>
            <Col lg={4} md={6} sm={8} xs={12}>
            <Form.Item colon={false} label="From">
              <Space direction="vertical">
                <DatePicker
                  defaultValue={moment(defaultOldDate, dateFormat)}
                  format={dateFormat}
                  onChange={onFromChange}
                />
              </Space>
            </Form.Item>
            </Col>
            <Col lg={4} md={6} sm={8} xs={12}>
            <Form.Item colon={false} label="To">
              <Space direction="vertical">
                <DatePicker
                  defaultValue={moment(defaultDate, dateFormat)}
                  format={dateFormat}
                  onChange={onToChange}
                />
              </Space>
            </Form.Item>
            </Col>
            <Col lg={4} md={6} sm={8} xs={24}>
            <Form.Item colon={false}>
              <Button type="primary" onClick={onGo}>
                Go
              </Button>
            </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{overflow:"auto"}}>
        <Table key="id" columns={columns1} dataSource={pickupService.concat((()=>{
          let amount = 0;
          let quantity = 0;
          for (let row of pickupService) {
            amount += row["amount"];
            quantity += row["quantity"];
          }
          return { key: "total", amount, quantity };
        })())} pagination={false} />
      </div>
      {/* <Row gutter={3}>
            <Col lg={4} md={6} sm={8} xs={12}><lable>Cow Milk</lable></Col>
            <Col lg={4} md={6} sm={8} xs={12}>
              <lable>Quantity:</lable>
              <Input  disabled={true}></Input>
            </Col>
            <Col lg={4} md={6} sm={8} xs={12}>
            <lable>Total Amount:</lable>
              <Input ></Input>
            </Col>
          </Row> */}
          
          
                <div>
                  <p>Total Quantity:{TotalQuantity}</p>
                  <p>Total Amount: {TotalAmount}</p>
                </div>
             
          <div>
        <button onClick={() => exportPDF()}>Download Summery</button>
      </div>
    </div>
   
  );
};

export default CollactionSummerys;
