import { Button, Col, Form, Input, Modal, Row, Select, IconButton ,DatePicker,
  Space} from "antd";
import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import InvoiceSales from "./InvoiceSalse";
import moment from "moment";


const { Option } = Select;


const SalsePopup = (props) => {
  const user = useContext(UserContext);
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  let defaultOldDate = new Date();
  defaultOldDate.setDate(defaultOldDate.getDate() - 30);
  const [fromDate, setFromDate] = useState(defaultOldDate);
  const [toDate, setToDate] = useState(defaultDate);

  const [customerList, setcustomer] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [isModelOpen1, setIsModalOpen1] = useState(false);
  const [pickupService, setPickupService] = useState([]);
  const [Customer, setCustomer] = useState([]);


  useEffect(() => {
    getCustomer();
  }, []);

  const getCustomer = () => {
    axiosInstance.get(`/scustomer/user1/${user.companyId}`).then((res) => {
      setcustomer(res.data.data);
    });
  };
  const getPickup = () => {
   
  }; 
 // .post(`/report/monthly/6448c49709322728481bc6da`,{

  
  const onAdd = () => {
    setIsModalOpen1(true)
  
  }
  const onCancelInvoice = () => {
    setIsModalOpen1(false)
  }
  const onCustomeridChange = (e) => {
    setCustomerId(e);
    axiosInstance
    .get(`/scustomer/${e}`)
    .then((res) => {
      setCustomer(res.data.data);

    });
    axiosInstance
    .post(`/selesreport/monthly/${user.companyId}`,{
      fromDate: fromDate,
      toDate: toDate,
      customerId:e
    })
    .then((res) => {
      setPickupService(res.data.data);
    });
  };
  const onFromChange = (date, dateString) => {
    setFromDate(date, dateString);
    axiosInstance
    .post(`/selesreport/monthly/${user.companyId}`,{
      fromDate: date,
      toDate: toDate,
      customerId:customerId
    })
    .then((res) => {
      setPickupService(res.data.data);
    });
  };
  const onToChange = (date, dateString) => {
    setToDate(date, dateString);
    axiosInstance
    .post(`/selesreport/monthly/${user.companyId}`,{
      fromDate: fromDate,
      toDate: date,
      customerId:customerId
    })
    .then((res) => {
      setPickupService(res.data.data);
    });
  };
  const dateFormat = "DD/MM/YYYY";
  return (
    <>
      <div>
        <Modal
          title="Scheme Detail"
          open={props.isVisible}
          onCancel={props.onCancel}
          width={"500px"}
          footer={[
            <Col span={24} className="footer">
              <div>
                <Button key="back" onClick={props.onCancel}>
                  Cancel
                </Button>
                <Button
                  key="submit"
                  onClick={onAdd}
                  style={{
                    background: "rgb(254, 197, 90)",
                    color: "white",
                    fontSize: "15px",
                  }}
                >
                  Download
                </Button>
                
              </div>
              <InvoiceSales
                  isVisible={isModelOpen1}
                  onCancel={onCancelInvoice}
                  setIsModalOpen1={setIsModalOpen1}
                  Customer={Customer}
                  pickupService={pickupService}
                  member ={props.member}
                />

            </Col>,
          ]}
        >
          <Row style={{ paddingTop: "20px" }}>
            <div className="rajho"> 
              <Form.Item colon={false} label=" Customer">
                <Select
                  placeholder="Customer"
                  value={customerId}
                  onChange={onCustomeridChange}
                >
                  {/* <Option value='0' >--Select Customer--</Option> */}
                  {customerList &&
                    customerList.map((customerList) => (
                      <Option key={customerList._id}>{customerList.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
          </Row>
          <div className="text">
            <label >From</label>
          <Form.Item colon={false} >
                <Space direction="vertical">
                  <DatePicker
                  className="01pik"
                    defaultValue={moment(defaultOldDate, dateFormat)}
                    format={dateFormat}
                    onChange={onFromChange}
                  />
                </Space>
              </Form.Item>

          </div>

          <div className=" text">
            <label >To</label>
          <Form.Item colon={false} >
                <Space direction="vertical">
                  <DatePicker
                  className="01pik"
                    defaultValue={moment(defaultDate, dateFormat)}
                    format={dateFormat}
                    onChange={onToChange}
                  />
                </Space>
              </Form.Item>

          </div>
        </Modal>
      </div>
    </>
  );
};

export default SalsePopup;
