import React, { useEffect, useState, useContext } from "react";
import {
  Col,
  Form,
  Row,
  Button,
  Input,
  message,
  Table,
  Modal,
} from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { PageTitle } from "../../PageTitle";
import moment from 'moment'
const DefaultUserSetting = () => {
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());
  const user = useContext(UserContext);
  const [snf, setSnf] = useState("");
  const [fat, setFat] = useState("");
  const [dateFormate, setDateFormate] = useState(defaultDate);
  const [showCompanyName, setShowCompanyName] = useState("");
  const [showUserName, setShowUserName] = useState("");
  const [deafultUser, setDefaulteUser] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [readDefaultUserObj, setReadDefaultUserObj] = useState({});

  useEffect(() => {
    let mounted = true;
    if (mounted) getDefaultUser();
    return () => (mounted = false);
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "SNF",
      dataIndex: "snf",
      key: "snf",
    },
    {
      title: "FAT",
      dataIndex: "fat",
      key: "fat",
    },
    {
      title: "Comapny Name",
      dataIndex: "showCompanyName",
      key: "showCompanyName",
    },
    {
      title: "User Name",
      dataIndex: "showUserName",
      key: "showUserName",
    },
    {
      title: "Date",
      dataIndex: "dateFormate",
      key: "dateFormate",
      render:(dateFormate)=>{
        return(
          <div>{moment(dateFormate).format('DD-MMM-yyyy')}</div>
        )
      }
    },
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
          <>
            <EyeOutlined
              onClick={() => {
                readDefaultUser(row);
              }}
            />
            <EditOutlined
              style={{ marginLeft: 12 }}
              // onClick={() => {
              //   modify(row);
              // }}
            />
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removeDefaultUser(row);
              }}
            />
          </>
        );
      },
    },
  ];

  const getDefaultUser = () => {
    axiosInstance.get(`/defaultUserSetting`).then((res) => {
      setDefaulteUser(res.data.data);
    });
  };
  const readDefaultUser = (obj) => {
    axiosInstance.get(`/defaultUserSetting/${obj.id}`).then((response) => {
      setReadDefaultUserObj(response.data.data);
    });
    setIsModalOpen(true);
  };
  const onSave = () => {
    const data = {
      snf: snf,
      fat: fat,
      dateFormate: dateFormate,
      showCompanyName: showCompanyName,
      showUserName: showUserName,
      userId: parseInt(user.userId),
      companyId:1
    };
    axiosInstance.post(`/defaultUserSetting`, data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
      } else message.error("Something wrong. Please try again...!");
    });
  };

  const removeDefaultUser = (obj) => {
    Modal.confirm({
      title: "Do you want to remove this Member?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosInstance.delete(`/defaultUserSetting/${obj.id}`).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            message.success("Record Deleted successfully");
          } else message.error("Something wrong. Please try again...!");
        });
      },
      onCancel() {},
    });
  };
  const onSetDate = (event) => {
    setDateFormate(new Date(event.target.value));
  };

  return (
   <div>
        <div>
      <div className="text-center main-heading">
      {/* <PageTitle title="Default User Setting">
        </PageTitle> */}
        <h1>Default User Setting</h1>
        </div>
      </div>
     <div className="default-user-setting container">
   
     
      <div>
        <Form  name="basic">
          <div className="row mb-3 end">
            <div className="col-md-4 text">
              <label className="my">SNF</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="SNF"
                  value={snf}
                  onChange={(e) => setSnf(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="col-md-4 text">
              <label className="my">FAT</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="FAT"
                  value={fat}
                  onChange={(e) => setFat(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="col-md-4 text">
              <label className="my">Comapny Name</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="Comapny Name"
                  value={showCompanyName}
                  onChange={(e) => setShowCompanyName(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="col-md-4 text">
              <label className="my">User Name</label>
              <Form.Item colon={false} className="raj0101">
                <Input
                  placeholder="User Name"
                  value={showUserName}
                  onChange={(e) => setShowUserName(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="col-md-4 text">
              <label className="my">Date</label>
              <Form.Item colon={false}  className="raj0101">
                <Input disabled={true} value={dateFormate.toLocaleDateString("CA")} onChange={onSetDate}/>
              </Form.Item>
            </div>
            <div className="col-md-4 text">
            <div>
              <Button className="btnmy" onClick={onSave}>
                Save
              </Button>
            </div>
          </div>
          </div>
         
        </Form>
      </div>
      <div>
        <Table columns={columns} dataSource={deafultUser} />
      </div>
      <Modal
        title="Default User Setting List"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <ul className="list-group w-50">
          <li className="list-group-item"> ID : {readDefaultUserObj.id}</li>
          <li className="list-group-item"> SNF : {readDefaultUserObj.snf}</li>
          <li className="list-group-item"> FAT : {readDefaultUserObj.fat}</li>
          <li className="list-group-item">
            {" "}
            Comapny Name : {readDefaultUserObj.showCompanyName}
          </li>
          <li className="list-group-item">
            {" "}
            User Name : {readDefaultUserObj.showUserName}
          </li>
          <li className="list-group-item">
            {" "}
            Date : {moment(readDefaultUserObj.dateFormate).format("DD-MM-yyyy")}
          </li>
          <li className="list-group-item">
            {" "}
            Company Id : {readDefaultUserObj.companyId}
          </li>
          <li className="list-group-item">
            {" "}
            User Id : {readDefaultUserObj.userId}
          </li>
        </ul>
      </Modal>
    </div>
   </div>
  );
};

export default DefaultUserSetting;
