import React, { useEffect, useState,useContext } from "react";
import axiosInstance from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { Col, Form, Row, Input, Button, message, Select, } from "antd";
import { PageTitle } from "../../PageTitle";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../globalContext";


const { Option } = Select;

const VehicleEditScreen = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  const user= useContext(UserContext);

  var vehicleObj = {
    id: id,
    name: "",
    registrationNo: "",
    contactNumber: "",
    contactPerson: "",
    vehcleType: "",
  };
  const [vehicleData, setVehicleData] = useState(vehicleObj);
  const [vechicleTypeList, setVechicleTypeList] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad()
;
    return () => (mounted = false);
  }, []);
  
  const pageLoad = () => {
    onReadVehicle(id);
    getVehicleType();
  }
  const onReadVehicle = (id) => {
 
    axiosInstance.get(`/vehicle/${id}`).then((response) => {
      setVehicleData(response.data.data);
    });
  };
  const getVehicleType = () => {
    axiosInstance.get(`/vehicleType`).then((response) => {
      setVechicleTypeList(response.data.data);
    });
  };
  const onUpdateVehicle = () => {
    axiosInstance.put(`/vehicle/${id}`, vehicleData).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record Update successfully");
        navigate("/VehicleScreen", { replace: true });
      } else message.error("Something wrong. Please try again...!");
    });
  };

  const onRegistrationNo = (e) => {
    setVehicleData({ ...vehicleData, registrationNo: e.target.value });
  };
  const onNameChange = (e) => {
    setVehicleData({ ...vehicleData, name: e.target.value });
  };
  const onContactNumber = (e) => {
    setVehicleData({ ...vehicleData, contactNumber: e.target.value });
  };
  const onContactPerson = (e) => {
    setVehicleData({ ...vehicleData, contactPerson: e.target.value });
  };
  const onVehicletypeChange = (e) => {
    setVehicleData({ ...vehicleData, vehcleType: e });
  };

  
  return (
   <div>
       <div>
        <div className=" text-center main-heading">
          {/* <PageTitle title="Edit Member"></PageTitle> */}
          <h1>Edit Member</h1>
        </div>
      </div>
     <div className="member-edit-screen container">
   

      <Form
        name="basic"
    
      >
<div className="row mb-3 end">
<div  className="col-md-4 text">
          <div>
          <label className="my">Name</label>
            <Form.Item colon={false}>
              <Input
                placeholder="Name"
                onChange={onNameChange}
                value={vehicleData.name}
              />
            </Form.Item>
          </div>
        </div>
        <div  className="col-md-4 text">
          <div>
          <label className="my">Registration No</label>
            <Form.Item colon={false} >
              <Input
                placeholder="Registration No"
                onChange={onRegistrationNo}
                value={vehicleData.registrationNo}
              />
            </Form.Item>
          </div>
        </div>
        
        <div  className="col-md-4 text">
          <div>
          <label className="my">Contact Number</label>
            <Form.Item colon={false}>
              <Input
                placeholder="Contact Number"
                onChange={onContactNumber}
                value={vehicleData.contactNumber}
              />
            </Form.Item>
          </div>
        </div>

        <div  className="col-md-4 text">
          <div>
          <label className="my">Contact Person</label>
            <Form.Item colon={false}  className="raj0101">
              <Input
                placeholder="Contact Person"
                onChange={onContactPerson}
                value={vehicleData.contactPerson}
              />
            </Form.Item>
          </div>
        </div>
        <div className="col-md-4 text">
            <label className="my">Vehicle Type</label>
            <Form.Item colon={false} className="raj0101">
              <Select
                placeholder="Vehicle Type"
                value={vehicleData.vehcleType}
                onChange={onVehicletypeChange}
              >
                {vechicleTypeList &&
                  vechicleTypeList.map((vechicleTypeList) => (
                    <Option key={vechicleTypeList.name}>
                      {vechicleTypeList.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>

        <div  className="col-md-4 text">
          <div>
      
            <Button className="btnmy" onClick={() => onUpdateVehicle()}>
              Update
            </Button>
          </div>
        </div>
</div>
      </Form>
    </div>
   </div>
  );
};
export default VehicleEditScreen;