import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row, Button, Input, message, Table, Modal } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { PageTitle } from "../../PageTitle";
import { useNavigate } from "react-router-dom";
const VehicleTypeScreen = () => {
  let navigate = useNavigate();

  const user= useContext(UserContext)
  const [name, setName] = useState("");
  const [vehicleTypeData, setVehicleTypeData] = useState([]);
  const [readVehicleObj, setReadVehicleObj] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyId, setCompanyId] = useState('');
  

  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);
  
  const pageLoad = () => {
    getVehicleType();
    setCompanyId(user.companyId );
  }
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Company Name",
      render: (row) => {
        return(
          <>
          {row.companyId?.name}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
          <>
            <EyeOutlined
            onClick={() => {
              readVehicleType(row);
            }}
            />
            <EditOutlined
              style={{ marginLeft: 12 }}
              onClick={() => {
                modify(row);
              }}
            />
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removeVehicleType(row);
              }}
            />
          </>
        );
      },
    },
  ];

  const getVehicleType = () => {
    axiosInstance.get(`/vehicleType`).then((response) => {
      setVehicleTypeData(response.data.data);
    });
  };
  const modify = (obj) => {
    navigate(`/VehicleTypeEditScreen/${obj._id}`, { replace: true });
  };
  const readVehicleType=(obj)=>{
    axiosInstance.get(`/vehicleType/${obj.id}`).then((response)=>{
        setReadVehicleObj(response.data.data);
    });
    setIsModalOpen(true);
  }

  const onSave = () => {
    const data = {
      name: name,
      companyId:companyId,
      userId:parseInt(user.userId)
    };
    axiosInstance.post(`/vehicleType`, data).then((res) => {
        if (res.data && res.data.responseCode === -1) {
          message.error("Record Already Exists");
        } else if (res.data && res.data.responseCode === 1) {
          message.success("Record saved successfully");
          getVehicleType();
          setName("")
        } else message.error("Something wrong. Please try again...!");
      });
  };

  const removeVehicleType=(obj)=>{
    Modal.confirm({
        title: "Do you want to remove this Member?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          axiosInstance.delete(`/vehicleType/${obj.id}`).then((res) => {
            if (res.data && res.data.responseCode === 1) {
              message.success("Record Deleted successfully");
            } else message.error("Something wrong. Please try again...!");
          });
        },
        onCancel() {},
      });
  }
  return (
    <div >
       <div>
      <div className="text-center main-heading">
      {/* <PageTitle title="Vehicle Type">
        </PageTitle> */}
        <h1>Vehicle Type</h1>
        </div>
      </div>
      <div className="vehicle-type-screen container">
     
      
      <div>
        <Form name="basic" className="">
           <div className="row mb-3 end">
            <div className="col-md-4 text">
              <label className="my">Name</label>
            <Form.Item colon={false} className="raj0101">
              <Input
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="col-md-4 text">
            <Button className="btnmy" onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
       
        </Form>
      </div>
      <div>
        <Table columns={columns} dataSource={vehicleTypeData} />
      </div>
      <Modal
        title="Vehicle Type List"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <ul className="list-group w-50">
          <li className="list-group-item"> ID : {readVehicleObj.id}</li>
          <li className="list-group-item"> Type : {readVehicleObj.name}</li>
          <li className="list-group-item"> Company Id : {readVehicleObj.companyId?.name}</li>
          <li className="list-group-item"> User Id : {readVehicleObj.userId}</li>
         
        </ul>
      </Modal>

    </div>
    </div>
  );
};

export default VehicleTypeScreen;
