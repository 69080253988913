import { Col, Form, Row, Button, Input, message, Table, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { PageTitle } from "../../PageTitle";

const SnfScreen = () => {
  const user=useContext(UserContext)
  const [value, setValue] = useState("");
  const [snfData, setSnfData] = useState([]);
  const [readSnfObj, setReadSnfObj] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyId, setCompanyId] = useState('');

  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);
  
  const pageLoad = () => {
    getSnf();
    setCompanyId(user.companyId );
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Company Name",
      render: (row) => {
        return(
          <>
          {row.companyId?.name}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
          <>
            <EyeOutlined
              onClick={() => {
                readSnf(row);
              }}
            />
            <EditOutlined
              style={{ marginLeft: 12 }}
              // onClick={() => {
              //   modify(row);
              // }}
            />
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removeSnf(row);
              }}
            />
          </>
        );
      },
    },
  ];

  const getSnf = () => {
    axiosInstance.get(`/snf`).then((response) => {
      setSnfData(response.data.data);
    });
  };

  const readSnf = (obj) => {
    axiosInstance.get(`/snf/${obj.id}`).then((response) => {
      setReadSnfObj(response.data.data);
    });
    setIsModalOpen(true);
  };
  const onSave = () => {
    const data = {
      value: value,
      companyId: companyId,
      userId:parseInt(user.userId)
    };
    axiosInstance.post(`/snf`, data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
      } else message.error("Something wrong. Please try again...!");
    });
  };

  const removeSnf = (obj) => {
    Modal.confirm({
      title: "Do you want to remove this Member?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosInstance.delete(`/snf/${obj.id}`).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            message.success("Record Deleted successfully");
          } else message.error("Something wrong. Please try again...!");
        });
      },
      onCancel() {},
    });
  };

  return (
   <div>
       <div>
      <div className="text-center main-heading">
      {/* <PageTitle title="Snf Screen">
        </PageTitle> */}
        <h1>Snf Screen</h1>
        </div>
      </div>
     <div className="master-snf-screen container">
    
      
      <div>
        <Form name="basic"
          
           >
          <div className="row mb-3 end">
            <div className="col-md-4 text">
              <Form.Item colon={false}  className="raj0101">
                <label className="my">value</label>
                <Input
                  placeholder="Value"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="col-md-4 text">
              <Button className="btnmy" onClick={onSave}>
                Save
              </Button>
            </div>
          </div>
          
        </Form>
      </div>
      <div>
        <Table columns={columns} dataSource={snfData} />
      </div>
      
      <Modal
        title="SNF-List"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <ul className="list-group w-50">
          <li className="list-group-item"> ID : {readSnfObj.id}</li>
          <li className="list-group-item"> Value : {readSnfObj.value}</li>
          <li className="list-group-item"> Company Id : {readSnfObj.companyId?.name}</li>
          <li className="list-group-item"> User Id : {readSnfObj.userId}</li>
         
        </ul>
      </Modal>
    </div>
   </div>
  );
};

export default SnfScreen;
