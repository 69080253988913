import React, { useState, useEffect, useContext } from "react";
import { Col, Row, Card, Avatar } from "antd";
import Icon, { HomeOutlined, MailOutlined,PhoneOutlined } from '@ant-design/icons';

import { PageTitle } from "../../PageTitle";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
const { Meta } = Card;
const MyAccount = () => {
  const user = useContext(UserContext);
  const memberObj = {};
  const [readMemberObj, setReadMemberObj] = useState(memberObj);

  useEffect(() => {
    let mounted = true;
    if (mounted) readMember();
    return () => (mounted = false);
  }, []);

  const readMember = () => {
    axiosInstance.get(`/member/${user.userId}`).then((response) => {
      setReadMemberObj(response.data.data);
    });
  };

  return (
    <div>



<div>
      <div className="text-center main-heading">
      {/* <PageTitle title="Vehicle">
        </PageTitle> */}
        <h1>My Account</h1>
        </div>
      </div>
      {/* <Row>
        <Col span={10}>
          <PageTitle title="My Account"></PageTitle>
        </Col>
      </Row> */}
      {/* <div className="site-card-border-less-wrapper">
        {readMemberObj.length > 0 && (
          <Card
            title="Profile"
            bordered={true}
            style={{
              width: 300,
              marginTop: 16,
            }}
          >
            <Meta
              avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
              description={readMemberObj.map((readMemberObj) => (
                <h1 key={readMemberObj.id}>{readMemberObj.name}</h1>
              ))}
            />
            <Meta
              description={
                <p ><Icon component={MailOutlined} /> {readMemberObj.email}</p>
              }
            />
            <Meta
              description={
                <p ><Icon component={PhoneOutlined} /> {readMemberObj.mobile}</p>
             }
            />
            <Meta
              description={readMemberObj.map((readMemberObj) => (
                <p key={readMemberObj.id}> <Icon component={HomeOutlined} /> {readMemberObj.address}</p>
              ))}
            />
          </Card>
        )}
      </div> */}

      




      {/* <Row>
        <Col>
          Name:{readMemberObj.name}
        </Col>
      </Row>
      <Row>
        <Col>
          Mobile no.:{readMemberObj.mobile}
        </Col>
      </Row>
      <Row>
        <Col>
          Email:{readMemberObj.email}
        </Col>
      </Row>
      <Row>
        <Col>
          Address:{readMemberObj.address}
        </Col>
      </Row> */}
      <section className="vh-100" style={{ backgroundColor: "#f4f5f7" }}>
      <div className="container py-5">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col col-lg-10 mb-4 mb-lg-0">
            <div className="card mb-3 shadow p-3 mb-5 bg-body rounded" style={{ borderRadius: ".5rem" }} >
              <div className="row g-0">
                <div className="col-md-4 gradient-custom text-center text-white hkborder pb-5"
                  >
                  <img 
                    src="https://img.icons8.com/avantgarde/100/test-account.png"
                    alt="Avatar"
                    className="img-fluid my-5 rounded-circle border border-dark border-3"
                    style={{ width: "80px" }}
                  />
              
                  <h5>{readMemberObj.name}</h5>
                  <p>{readMemberObj.address}</p>
                  <i className="far fa-edit mb-5"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"/></svg></i>
                </div>
                <div class="col-md-8">
              <div class="card-body p-4">
                <h6>Information</h6>
                <hr class="mt-0 mb-4"/>
                <div class="row pt-1">
                  <div class="col-6 mb-3">
                    <h6>Email</h6>
                    <p class="text-muted">{readMemberObj.email}</p>
                  </div>
                  <div class="col-6 mb-3">
                    <h6>Phone</h6>
                    <p class="text-muted">{readMemberObj.mobile}</p>
                  </div>
                </div>
                <h6>Projects</h6>
                <hr class="mt-0 mb-4"/>
                <div class="row pt-1">
                  <div class="col-6 mb-3">
                    <h6>All</h6>
                    <p class="text-muted">Other details</p>
                  </div>
                  <div class="col-6 mb-3">
                    <h6>Most Viewed</h6>
                    <p class="text-muted">Other details</p>
                  </div>
                </div>
                {/* <div class="d-flex justify-content-center">
                  <a href="#!"><i class="fab fa-facebook-f fa-lg me-3">
                  <svg xmlns="http://www.w3.org/2000/svg" height="2em" color="#3b71ca" viewBox="0 0 320 512"><path className="ph" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>
                    
                    </i></a>
                  <a href="#!"><i class="fab fa-twitter fa-lg me-3">
                  <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 512 512"><path className="ph" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg></i></a>
                  <a href="#!"><i class="fab fa-instagram fa-lg">
                  <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512"><path className="ph" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></i></a>
                </div> */}
              </div>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default MyAccount;