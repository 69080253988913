import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Table,
  Input,
  message,
  Form,
  Button,
  DatePicker,
  Space,
  
} from "antd";
import { PageTitle } from "../../PageTitle";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import moment from "moment";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import SalsePopup from "./../InvoicePdf/FormPopupSalse"
import Highlighter from "react-highlight-words";
import jsPDF from "jspdf";
import "jspdf-autotable";

const SalseCollactionSummery = () => {
  const user = useContext(UserContext);
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  let defaultOldDate = new Date();
  defaultOldDate.setDate(defaultOldDate.getDate() - 30);

  const [pickupService, setPickupService] = useState([]);
  const [member, setMember] = useState({});
  const [fromDate, setFromDate] = useState(defaultOldDate);
  const [toDate, setToDate] = useState(defaultDate);
  const [isModelOpen, setIsModalOpen] = useState(false);
  const [groupedData, setGroupedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, pickupService) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(pickupService);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (pickupService) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${pickupService}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, pickupService)
          }
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, pickupService)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(pickupService);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[pickupService]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === pickupService ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const onSchemeDetail = () => {
    setIsModalOpen(true);
  };
  const onCancel = () => {
    setIsModalOpen(false);
  };

  const onAdd = () => {
    window.location.href = `/Invoice`;
  };


  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();

    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getPickup();
    getMember();
   }

  const columns1 = [

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },

    {
      title: "Mo. No.",
      dataIndex: "Number",
      key: "Number",
      ...getColumnSearchProps("Number"),
    },
    {
      title: "From Date",
      render: (row) => {
        return (
          <>{row.sdate && <div>{moment(row.sdate).format("DD-MM-yyyy")}</div>}</>
        );
      },
    },
    {
      title: "To Date",
      render: (row) => {
        return (
          <>{row.ndate && <div>{moment(row.ndate).format("DD-MM-yyyy")}</div>}</>
        );
      },
    },
    {
      title: "Milk Type",
      dataIndex: "milkType",
      key: "milkType",
      ...getColumnSearchProps("milkType"),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Credit",
      dataIndex: "Credit",
      key: "Debit",
    },
    {
      title: "Pending",
      dataIndex: "Pending",
      key: "Pending",
    },

    
  ];
  const getMember = async() => {
    axiosInstance
      .get(`/member/${user.userId}`)
      .then((res) => {
        setMember(res.data.data);

      });
  };
  const getPickup = () => {
    axiosInstance
      .post(`/selesreport/monthly/${user.companyId}`)
      .then((res) => {
        setPickupService(res.data.data);
        getGroupData(res.data.data)
      });
  };
  const onGo = () => {
    axiosInstance
      .post(`/report/monthly/${user.companyId}`, {
        fromDate: fromDate,
        toDate: toDate,
      })
      .then((res) => {
        if (res.data && res.data.responseCode === -1) {
          message.error("Failed! please contact administor.");
        } else if (res.data && res.data.responseCode === 1) {
          message.success("Filter applied successfully");
          setPickupService(res.data.data);
          getGroupData(res.data.data)
        } else message.success("Filter applied successfully");
        setPickupService(res.data.data);
        getGroupData(res.data.data)
      });
  };
  const onFromChange = (date, dateString) => {
    setFromDate(date, dateString);
  };
  const onToChange = (date, dateString) => {
    setToDate(date, dateString);
  };

  const dateFormat = "DD/MM/YYYY";

  const downloadPdf = () => {
    const unit = "pt";
    const size = "A3";
    const orientation = "portrait";

    const marginLeft = 350;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Sales report";
    const headers = [
      [
        "CustomerName",
        "Mobile",
        "MilkType",
        "Quantity",
        "Amount",
        "FromDate",
        "ToDate",
      ],
    ];

    const data = groupedData.map((elt) => [
      elt.name,
      elt.Number,
      elt.milkType,
      elt.quantity,
      elt.amount,
      moment(elt.startDate).format("DD-MM-yyyy"),
      moment(elt.endDate).format("DD-MM-yyyy"),
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("SalesReport.pdf");
  };



  var groupData = [];

  const getGroupData = (responseData) => {
    var result = responseData.map((k, v) => {
      if (groupData.filter(x => x.name === k.customerName).length > 0) {

      }
      else {
        groupData.push({
          name: k.customerName,
          sdate:k.startDate,
          ndate:k.endDate,
          Number:k.number,
          milkType:"Mix",
         Credit:k.credit,
         Pending: pendingAmount(responseData.filter(x => x.customerName === k.customerName)),
          quantity: sumQuantity(responseData.filter(x => x.customerName === k.customerName)),
          amount: sumAmount(responseData.filter(x => x.customerName === k.customerName)),
          children: responseData.filter(x => x.customerName === k.customerName)
        })
      }
    });
    console.log("grupData",groupData)
    setGroupedData(groupData)
  }
  const pendingAmount = (arr) => {
    var total = 0;
    var Pending = 0;
    for (let i = 0; i < arr.length; i++) {  //loop through the array
      total += arr[i].amount; 
      Pending =  total-arr[i].credit;
    }
    return Pending.toFixed(2);
  }
  const sumQuantity = (arr) => {
    var total = 0;
    for (let i = 0; i < arr.length; i++) {  //loop through the array
      total += arr[i].quantity;  //Do the math!
    }
    return total;
  }
  const sumAmount = (arr) => {
    var total = 0;
    for (let i = 0; i < arr.length; i++) {  //loop through the array
      total += arr[i].amount;  //Do the math!
    }
    return total;
  }

  return (
    
    <div>
         <div>
          <div  className=" text-center main-heading bgcolor">
            {/* <PageTitle title="Monthly Collaction Summery"></PageTitle> */}
            <h1>Monthly Collection Summery</h1>
          </div>
        </div>
      <div className="collaction-summery-list container">
      <div>
         <Form name="basic">
         <div className="row mb-3 end">
          <div className="col-md-2 text">
            <label >From</label>
          <Form.Item colon={false} >
                <Space direction="vertical">
                  <DatePicker
                    defaultValue={moment(defaultOldDate, dateFormat)}
                    format={dateFormat}
                    onChange={onFromChange}
                  />
                </Space>
              </Form.Item>

          </div>

          <div className="col-md-2 text">
            <label >To</label>
          <Form.Item colon={false} >
                <Space direction="vertical">
                  <DatePicker
                    defaultValue={moment(defaultDate, dateFormat)}
                    format={dateFormat}
                    onChange={onToChange}
                  />
                </Space>
              </Form.Item>

          </div>

          <div className="col-md-2 text">
          <Form.Item colon={false}>
                <Button className="btnmy" onClick={onGo}>
                  Go
                </Button>
              </Form.Item>
          </div>

          <div className="col-md-2 text">
          <Form.Item colon={false} label="">
                <button className="btnmy" onClick={() => onSchemeDetail()}>
                  {/* <DownloadOutlined /> */}
                  <svg className="Adda" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg> Invoice
                </button>
                <SalsePopup
                  isVisible={isModelOpen}
                  onCancel={onCancel}
                  onAdd={onAdd}
                  member={member}
                />
              </Form.Item>
          </div>


          <div className="col-md-2 text">
          <Form.Item colon={false} >
                <button className="btnmy" onClick={() => downloadPdf()}>Download List</button>
              </Form.Item>
          </div>

         </div>
         </Form>

      </div>
      <div style={{ overflow: "auto" }}>
        {/* <Table key="id" columns={columns1} dataSource={pickupService} /> */}

        <Table
          size="small"
          indentSize={0}
          columns={columns1}
          dataSource={groupedData}
        />
      </div>
    </div>
  </div>
  );
};

export default SalseCollactionSummery;
