import { Col, Row, Form, Button, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import { PageTitle } from "../../PageTitle";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const CustomerEditScreen = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  var customerObj = {
    id: id,
    name: "",
    referenceBy: "",
    mapLocation: "",
    lattitude: "",
    longitude: "",
    registerDate: "",
    mobile: "",
    addressLine1: "",
    addressLine2: "",
    landmark: "",
    city: "",
    state: "",
    email: "",
  };

  const [customerData, setCustomerData] = useState(customerObj);
  const [cityList, setCityList] = useState();
  const [stateList, setStateList] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) onReadCustomer(id)
;
    getState();
    return () => (mounted = false);
  }, []);

  const getState = () => {
    axiosInstance.get("/state").then((response) => {
      setStateList(response.data.data);
    });
  };
  const onStateChange = (stateid, e) => {
    axiosInstance.get(`/city/state/${stateid}`).then((response) => {
      setCityList(response.data.data);
      setCustomerData({ ...customerData, state: stateid });
    });
  };

  const onCityChange = (e) => {
    setCustomerData({ ...customerData, city: e });
  };

  const onReadCustomer = (id) => {

    axiosInstance.get(`/customer/${id}`).then((response) => {
      setCustomerData(response.data.data);
    });
  };

  const onNameChange = (e) => {
    setCustomerData({ ...customerData, name: e.target.value });
  };
  const onEmailChange = (e) => {
    setCustomerData({ ...customerData, email: e.target.value });
  };

  const onMobileChange = (e) => {
    setCustomerData({ ...customerData, mobile: e.target.value });
  };
  const onRegistrationDateChange = (e) => {
    setCustomerData({ ...customerData, registerDate: e.target.value });
  };
  const onReferenceByChange = (e) => {
    setCustomerData({ ...customerData, referenceBy: e.target.value });
  };
  const onLandmarkChange = (e) => {
    setCustomerData({ ...customerData, landmark: e.target.value });
  };
  const onAddressLine1Change = (e) => {
    setCustomerData({ ...customerData, addressLine1: e.target.value });
  };
  const onAddressLine2Change = (e) => {
    setCustomerData({ ...customerData, addressLine2: e.target.value });
  };
  const onLattitudeChange = (e) => {
    setCustomerData({ ...customerData, lattitude: e.target.value });
  };
  const onLongitudeChange = (e) => {
    setCustomerData({ ...customerData, longitude: e.target.value });
  };

  const onUpdateCustomer = () => {
    axiosInstance.put(`/customer/${id}`, customerData).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record Update successfully");
        navigate("/CustomerListScreen", { replace: true });
      } else message.error("Something wrong. Please try again...!");
    });
  };

  return (
    <div >
          <div>
        <div className=" text-center main-heading">
          {/* <PageTitle title="Edit Customer"></PageTitle> */}
          <h1>Edit Customer</h1>
        </div>
      </div>
      <div className="customber-edit-screen container">

      <Form  name="basic">
        <div className="row mb-3 end">
          <div  className="col-md-4 text">
            <label className="my">Full Name</label>
          <Form.Item colon={false} >
                <Input
                  placeholder="Full Name"
                  value={customerData.name}
                  onChange={onNameChange}
                />
              </Form.Item>
          </div>
          <div className="col-md-4 text">
          <label className="my">Email</label>
          <Form.Item colon={false} >
                <Input
                  placeholder="Email"
                  value={customerData.email}
                  onChange={onEmailChange}
                />
              </Form.Item>
          </div>
          <div className="col-md-4 text">
          <label className="my">Mobile</label>
          <Form.Item colon={false} >
                <Input
                  placeholder="Mobile"
                  value={customerData.mobile}
                  onChange={onMobileChange}
                />
              </Form.Item>
          </div>

          <div className="col-md-4 text">
          <label className="my">Registration Date</label>
          <Form.Item colon={false} >
                <Input
                  placeholder="Registration Date"
                  value={customerData.registerDate}
                  onChange={onRegistrationDateChange}
                />
              </Form.Item>
          </div>

          <div className="col-md-4 text">
          <label className="my">Reference By</label>
          <Form.Item colon={false} >
                <Input
                  placeholder="Reference By"
                  value={customerData.referenceBy}
                  onChange={onReferenceByChange}
                />
              </Form.Item>
          </div>

              <div className="col-md-4 text">
              <label className="my">Landmark</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="Landmark"
                  value={customerData.landmark}
                  onChange={onLandmarkChange}
                />
              </Form.Item>
              </div>


              <div className="col-md-4 text">
              <label className="my">Address Line 1</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="Address Line 1"
                  value={customerData.addressLine1}
                  onChange={onAddressLine1Change}
                />
              </Form.Item>
              </div>

              <div className="col-md-4 text">
              <label className="my">Address Line 2</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="Address Line 2"
                  value={customerData.addressLine2}
                  onChange={onAddressLine2Change}
                />
              </Form.Item>
              </div>


              <div className="col-md-4 text">
              <label className="my">State</label>
              <Form.Item colon={false}>
                <Select
                  placeholder="State"
                  value={customerData.state}
                  onChange={onStateChange}
                >
                  {stateList &&
                    stateList.map((stateList) => (
                      <Option key={stateList.id}>{stateList.name}</Option>
                    ))}
                </Select>
              </Form.Item>
              </div>


              <div className="col-md-4 text">
              <label className="my">City</label>
              <Form.Item colon={false} >
                <Select
                  placeholder="City"
                  value={customerData.city}
                  onChange={onCityChange}
                >
                  {cityList &&
                    cityList.map((cityList) => (
                      <Option key={cityList.id}>{cityList.name}</Option>
                    ))}
                </Select>
              </Form.Item>
              </div>


              <div className="col-md-4 text">
              <label className="my">Lattitude</label>
              <Form.Item colon={false}>
                <Input
                  placeholder="lattitude"
                  value={customerData.lattitude}
                  onChange={onLattitudeChange}
                />
              </Form.Item>
              </div>

              <div className="col-md-4 text">
              <label className="my">Longitude</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="longitude"
                  value={customerData.longitude}
                  onChange={onLongitudeChange}
                />
              </Form.Item>
              </div>

              <div className="col-md-4 text">

              <div>
              <Button className="btnmy" onClick={() => onUpdateCustomer()}>
                Update
              </Button>
            </div>
              </div>


        </div>

      </Form>


    
    </div>
    </div>
  );
};

export default CustomerEditScreen;