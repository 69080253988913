import React, { useState, useEffect, useContext } from "react";
import {  Form,Input, Button, Select, message } from "antd";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "./../../globalContext";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const SCustomerAddScreen = () => {
 let navigate = useNavigate(); 
  const user = useContext(UserContext);
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  const [cityList, setCityList] = useState();
  const [stateList, setStateList] = useState();
  const [crate, setcrate] = useState();
  const [brate, setbrate] = useState();
  const [grate, setgrate] = useState();
  const [drate, setdrate] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState();
  const [landmark, setLandmark] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState("Bharat");
  const [registerDate, setRegisterDate] = useState(defaultDate);
  const [referenceBy, setReferenceBy] = useState();
  const [lattitude, setLattitude] = useState();
  const [longitude, setLongitude] = useState();
  const [companyId, setCompanyId] = useState('');
  const [memberId, setMemberId] = useState('');
  const [codeP, setCodeP] = useState();
  const [customerCode, setCustomerCode] = useState('');


  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);

  const pageLoad = () => {
    getState();
    getCustomer();
    
      setCompanyId(user.companyId);
    setMemberId(user.memberId);
  }
  var Code=100 ;
  var cust1 ;
  const getCustomer = () => {
    axiosInstance.get(`/scustomer/user/${user.companyId}`).then((res) => {
      setCustomerCode(res.data.data);
      cust1=res.data.data;
      const results =  Promise.all(cust1.map(async (k, v) => {
        if(k!== undefined && k !== null){
            if(k.customerCode>=Code){
              Code=k.customerCode
             
            }
        }     
      })
      )
     setCodeP(Code+1)
        });
    };
  const getState = () => {
    axiosInstance.get("/state").then((response) => {
      setStateList(response.data.data);
    });
  };
  const onStateChange = (stateid,e) => {
    setState(e.children)
    setCity("")

    axiosInstance.get(`/city/state/${stateid}`).then((response) => {
      setCityList(response.data.data);
    });
  };

  const onCityChange = (e) => {
    setCity(e);
  };

  const onAdd = () => {
    const data = {
      email: email,
      name: name,
      mobile: mobile,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      landmark: landmark,
      registerDate: registerDate,
      referenceBy: referenceBy,
      city: city,
      state: state,
      country: country,
      lattitude: lattitude,
      longitude: longitude,
      userId: parseInt(user.userId),
      companyId: companyId,
      memberId: memberId,
      crate: crate,
      brate: brate,
      grate: grate,
      drate: drate,
      customerCode:codeP,
      isDeleted:false,
    };

    axiosInstance.post("/scustomer", data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
         navigate('/SCustomerList', { replace: true });
      } else message.error("Something wrong. Please try again...!");
    });
  };
  const onSetDate = (event) => {
    setRegisterDate(new Date(event.target.value));
  };

  return (
    <div>
        <div>
        <div className=" text-center main-heading">
          {/* <PageTitle title="Add Customer"></PageTitle> */}
          <h1>Add Customer</h1>
        </div>
      </div>
      <div className="customber-add-screen container">

        <Form name="basic">
            <div  className="row mb-3 end">
              <div className="col-md-4 text">
              <div>
                <label className="my">Full Name</label>
              <Form.Item
                colon={false}
                
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Name!",
                  },
                ]}
              >
                <Input
                  placeholder="Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus={true}
                />
              </Form.Item>
            </div>
              </div>


              <div className="col-md-4 text">
              <label className="my">Email</label>

              <Form.Item
                colon={false}
             
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input valid Email!",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
            </div>


            <div className="col-md-4 text">
            <label className="my">Mobile</label>
              <Form.Item
                colon={false}
              
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: "Please input your Mobile!",
                  },
                ]}
              >
                <Input
                  placeholder="Mobile"
                  value={mobile}
                  maxLength={10}
                  minLength={10}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <label className="my">Registration Date</label>
              <Form.Item colon={false} >
                <Input
                  disabled={true}
                  value={registerDate.toLocaleDateString("CA")}
                  onChange={onSetDate}
                />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <label className="my">Reference By</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="Reference By"
                  value={referenceBy}
                  onChange={(e) => setReferenceBy(e.target.value)}
                />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <label className="my">Landmark</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="Landmark"
                  value={landmark}
                  onChange={(e) => setLandmark(e.target.value)}
                />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <label className="my">Address Line 1</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="Address Line 1"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <label className="my">Address Line 2</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="Address Line 2"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
              </Form.Item>
            </div>


            <div className="col-md-4 text">
            <label className="my">Country</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="Bharat"
                  value={country}
                  onChange={(e) => setCountry("Bharat")}
                />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <label className="my">State</label>
              <Form.Item colon={false} >
                <Select
                  placeholder="State"
                  value={state}
                  onChange={(stateid,e) => onStateChange(stateid,e)}>
                  {stateList &&
                    stateList.map((u) => (
                      <Option key={u._id} >{u.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <label className="my">City</label>
              <Form.Item colon={false} >
                <Select
                  placeholder="City"
                  value={city}
                  onChange={onCityChange}
                >
                  {cityList &&
                    cityList.map((cityList) => (
                      <Option key={cityList.name}>{cityList.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </div>


            <div className="col-md-4 text">
            <label className="my">Cow Rate</label>
              <Form.Item colon={false} >
              <Input
                  placeholder="Cow Rate"
                  value={crate}
                  onChange={(e) => setcrate(e.target.value)}
                />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <label className="my">Buff. Rate</label>
              <Form.Item colon={false} label="">
                <Input
                  placeholder="Buff. Rate"
                  value={brate}
                  onChange={(e) => setbrate(e.target.value)}
                />
              </Form.Item>
            </div>


            <div className="col-md-4 text">
            <label className="my">Gir Cow Rate</label>
              <Form.Item colon={false} >
              <Input
                  placeholder="Gir Cow Rate"
                  value={grate}
                  onChange={(e) => setgrate(e.target.value)}
                />
              </Form.Item>
            </div>


            <div className="col-md-4 text">
            <label className="my">Desi Cow Rate</label>
              <Form.Item colon={false} >
                <Input
                  placeholder="Desi Cow Rate"
                  value={drate}
                  onChange={(e) => setdrate(e.target.value)}
                />
              </Form.Item>
            </div>


            <div className="col-md-4 text">
            <label className="my">Lattitude</label>
              <Form.Item colon={false}  className="raj0101">
                <Input
                  placeholder="lattitude"
                  value={lattitude}
                  onChange={(e) => setLattitude(e.target.value)}
                />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <label className="my">Longitude</label>
              <Form.Item colon={false}  className="raj0101">
                <Input
                  placeholder="longitude"
                  value={longitude}
                  onChange={(e) => setLongitude(e.target.value)}
                />
              </Form.Item>
            </div>


            <div className="col-md-4 text">
              <Button
                className="btnmy"
                htmlType="submit"
                onClick={() => onAdd()}
              >
                Add
              </Button>
            </div>








            </div>
        </Form>
    
    
    </div>
    </div>
  );
};

export default SCustomerAddScreen;
