import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Layout, Menu, Dropdown } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { environment } from "./Environment";
import iconUser from "../src/images/Icon-user.svg";
import logo from "../src/images/logo.jpg";
import Icon from '@ant-design/icons';
import img from "../src/images/Orbotlogop.png";
import Hamburger from 'hamburger-react';
import { useState } from 'react';



import { useNavigate } from "react-router-dom";

const { Header } = Layout;

export const Nav = (props) => {
  let navigate = useNavigate();

  useEffect(() => {

    let mounted = true;
    if (mounted) {
      console.log("role", props.authToken.role.name)

      if (props.authToken.role.name !== null) {

      }


    }
    return () => (mounted = false);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    // navigate(`${environment.url.AUTH_URL}`, { replace: true });
    // navigate('/LoginScreen', { replace: true });
    // window.location.href=`${environment.url.AUTH_URL}`;
    window.location.href = `/`;
  }

  const myaccount = () => {
    navigate('/MyAccount', { replace: true });

  }

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <button className="ant-btn-link" onClick={myaccount}>My Account</button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <button className="ant-btn-link" onClick={handleLogout}>Logout</button>
      </Menu.Item>
    </Menu>
  )



  function addMonths(date1, months) {
    date1.setMonth(date1.getMonth() + months);

    return date1;
  }
  const date = new Date(props.authToken.registerDate);

  const result2 = addMonths(date, 2);

  const diffTime = Math.abs(result2 - new Date());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


  document.querySelectorAll('.dropdown-toggle').forEach(item => {
    item.addEventListener('click', event => {

      if (event.target.classList.contains('dropdown-toggle')) {
        event.target.classList.toggle('toggle-change');
      }
      else if (event.target.parentElement.classList.contains('dropdown-toggle')) {
        event.target.parentElement.classList.toggle('toggle-change');
      }
    })
  });



  const [raj, setFormVisible] = useState(false);

  const click01 = () => {
    setFormVisible(!raj)
  };

  return (
    <div>

      <div>


      </div>
      <div>

        <label onClick={click01} id="burger" for="menyAvPaa">

          <div onClick={click01} className="rajkamal02" id="burger">
            <svg className="rajakamalsvg" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
            {/* <Hamburger onClick={click01} /> */}

          </div>

        </label>

        {raj && (

          <div>
            <input type="checkbox" id="menyAvPaa" />


            <div id="meny" className="col-md-6">

              <div className="myminimenu">
                <div class="navbar-collapse" >
                  <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item dropdown">
                      {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'PCOMPANY') && <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Masters
                      </a>}
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'PCOMPANY') && <li><a onClick={click01} class="dropdown-item" href="#/FatsnFRateMatrix">Rate matrix</a></li>}
                        {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/FatScreen"> Fat</a></li>}
                        {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/SnfScreen">Snf</a></li>}
                        {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/RoleScreen">role</a></li>}
                        {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'PCOMPANY') && <li><a onClick={click01} class="dropdown-item" href="#/VehicleScreen"> Vehicle</a></li>}
                        {(props.authToken.role.name === 'Admin') && <li><a onClick={click01} class="dropdown-item" href="VehicleTypeScreen">Vehicle type</a></li>}
                        {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'PCOMPANY') && <li><a onClick={click01} class="dropdown-item" href="#/RouteScreen">Route</a></li>}
                        {(props.authToken.role.name === 'Admin') && <li><a onClick={click01} class="dropdown-item" href="#/UserRole">User role</a></li>}
                        {(props.authToken.role.name === 'Admin') && <li><a onClick={click01} class="dropdown-item" href="#/DefaultUserSetting"> Default User Setting</a></li>}
                      </ul>
                    </li>
                    <li class="nav-item dropdown">
                      {(props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'PCOMPANY') && <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Member
                      </a>}
                      {(props.authToken.role.name === 'PCOMPANY' || props.authToken.role.name === 'SCOMPANY') && <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a onClick={click01} class="dropdown-item" href="#/AddEmployee">Add member</a></li>
                        <li><a onClick={click01} class="dropdown-item" href="#/MemberListScreen">Member list</a></li>
                      </ul>}
                    </li>
                    <li class="nav-item dropdown">
                      {(props.authToken.role.name === 'Admin') && <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Member
                      </a>}
                      {(props.authToken.role.name === 'Admin') && <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a onClick={click01} class="dropdown-item" href="#/MemberListScreenAdmin">Active Member list</a></li>
                        <li><a onClick={click01} class="dropdown-item" href="#/DeletedMember">Deleted Member list</a></li>
                      </ul>}
                    </li>
                    <li class="nav-item dropdown">
                      {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'PCOMPANY') && <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Customer
                      </a>}
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        {(props.authToken.role.name === 'PCOMPANY') && <li><a onClick={click01} class="dropdown-item" href="#/CustomerListScreen">Pickup customer list</a></li>}
                        {(props.authToken.role.name === 'PCOMPANY') && <li><a onClick={click01} class="dropdown-item" href="#/CustomerAddScreen">Add pickup customer</a></li>}
                        {(props.authToken.role.name === 'SCOMPANY') && <li><a onClick={click01} class="dropdown-item" href="#/SCustomerList">Sales customer list</a></li>}
                        {(props.authToken.role.name === 'SCOMPANY') && <li><a onClick={click01} class="dropdown-item" href="#/SCustomer">Add sales customer</a></li>}
                        {(props.authToken.role.name === 'Admin') && <li><a onClick={click01} class="dropdown-item" href="#/ActiveCustomer">Active Pickup Customer</a></li>}
                        {(props.authToken.role.name === 'Admin') && <li><a onClick={click01} class="dropdown-item" href="#/DeletedCustomer">Deleted Pickup Customer</a></li>}
                        {(props.authToken.role.name === 'Admin') && <li><a onClick={click01} class="dropdown-item" href="#/ActiveSCustomer">Active Sales Customer</a></li>}
                        {(props.authToken.role.name === 'Admin') && <li><a onClick={click01} class="dropdown-item" href="#/DeletedSCustomer">Deleted Sales Customer</a></li>}
                      </ul>
                    </li>
                    {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'PCOMPANY' || props.authToken.role.name === 'PEMPLOYEE'|| props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'SEMPLOYEE') && <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Services
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'PCOMPANY' || props.authToken.role.name === 'PEMPLOYEE') && <li><a onClick={click01} class="dropdown-item" href="#/PickupAddScreen">Add pickup milk </a></li>}
                        {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'PCOMPANY' || props.authToken.role.name === 'PEMPLOYEE') && <li><a onClick={click01} class="dropdown-item" href="#/PickupListScreen">Pickup milk list</a></li>}
                        {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'SEMPLOYEE') && <li><a onClick={click01} class="dropdown-item" href="#/AddSals">Add sales milk</a></li>}
                        {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'SEMPLOYEE') && <li><a onClick={click01} class="dropdown-item" href="#/SelsList"> Sales milk list</a></li>}
                      </ul>
                    </li>}
                    <li class="nav-item dropdown">
                      {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'PCOMPANY') && <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Reports
                      </a>}
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'PCOMPANY') && <li><a onClick={click01} class="dropdown-item" href="#/MonthelyCollaction">Pickup milk report </a></li>}
                        {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY') && <li><a onClick={click01} class="dropdown-item" href="#/SalseCollactionSummery">Sales milk report</a></li>}


                      </ul>
                    </li>
                    <li class="nav-item dropdown">
                      {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY'|| props.authToken.role.name === 'PCOMPANY') && <a  class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Payment
                      </a>}
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'PCOMPANY' || props.authToken.role.name === 'SCOMPANY') && <li><a onClick={click01} class="dropdown-item" href="#/Payment">Payment </a></li>}

                      </ul>
                    </li>
                  </ul>
                  {/* <ul class="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
                    <li class="nav-item dropdown">
                      <div className="d-flex justify-content-center hemuicon">
                        <a href="#/Support" onClick={click01}><h2 className="hemucon">Support
                         </h2></a>
                      </div>
                    </li>
                  </ul> */}
                  <ul class="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
                    <li><a class="dropdown-item 02 black077" href="#/Support" onClick={click01} > Support <svg className="black01" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"/></svg></a></li>
                  </ul>
                  <ul class="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
                    <li><a class="dropdown-item 02" href="#/Notification" onClick={click01} > Notification <svg className="bellsvg" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z" /></svg></a></li>
                  </ul>
                  <ul class="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
                    <li class="nav-item dropdown">
                      <a  class="nav-link dropdown-toggle" href="#" id="navbarDropdown 02" role="button" data-bs-toggle="dropdown" aria-expanded="false">

                        <div className="d-flex justify-content-center hemuicon">
                          <h2 className="hemucon">Account&Setting<Icon type="down" /></h2>
                        </div>


                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a onClick={click01} ><a class="dropdown-item" onClick={myaccount}> Account</a></a></li>
                        <li><a onClick={click01}><a class="dropdown-item" href="#"> Settings</a></a></li>
                        <li><a onClick={click01}><a class="dropdown-item" onClick={handleLogout}> Log out</a></a></li>


                        {(props.authToken.role.name === 'Admin') && <li><a onClick={click01}><a class="dropdown-item" href="#/FeedbackList"> Feedback List</a></a></li>}


                      </ul>
                    </li>
                  </ul>
                  {/* <ul class="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
                    <li class="nav-item dropdown">

                        <div className="d-flex justify-content-center hemuicon">
                          <h2 className="hemucon1">{diffDays} days left in your trial period<Icon type="down" /></h2>
                        </div>


                    </li>
                  </ul> */}

                </div>
              </div>

            </div>
          </div>
        )}

      </div>

      <nav class="navbar navbar-expand-lg navbar-dark bg-dark p-0">
        <div class="container mx-auto m-0 p-0">
          <a class="navbar-brand" href="#/HomeScreen"> <img src={img} alt='' style={{ width: "100%", alignItems: "center" }} /></a>
          {/* <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> */}
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item dropdown">
                {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'PCOMPANY') && <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Masters
                </a>}
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'PCOMPANY') && <li><a class="dropdown-item" href="#/FatsnFRateMatrix">Rate matrix</a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/FatScreen"> Fat</a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/SnfScreen">Snf</a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/RoleScreen">role</a></li>}
                  {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'PCOMPANY') && <li><a class="dropdown-item" href="#/VehicleScreen"> Vehicle</a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/VehicleTypeScreen">Vehicle type</a></li>}
                  {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'PCOMPANY') && <li><a class="dropdown-item" href="#/RouteScreen">Route</a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/UserRole">User role</a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/DefaultUserSetting"> Default User Setting</a></li>}
                </ul>
              </li>
              <li class="nav-item dropdown">
                {(props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'PCOMPANY') && <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Member
                </a>}
                {(props.authToken.role.name === 'PCOMPANY' || props.authToken.role.name === 'SCOMPANY') && <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" href="#/AddEmployee">Add member</a></li>
                  <li><a class="dropdown-item" href="#/MemberListScreen">Member list</a></li>
                </ul>}
              </li>
              <li class="nav-item dropdown">
                {(props.authToken.role.name === 'Admin') && <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Member
                </a>}
                {(props.authToken.role.name === 'Admin') && <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" href="#/MemberListScreenAdmin">Active Member list</a></li>
                  <li><a class="dropdown-item" href="#/DeletedMember">Deleted Member list</a></li>
                </ul>}
              </li>
              <li class="nav-item dropdown">
                {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'PCOMPANY') && <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Customer
                </a>}
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  {(props.authToken.role.name === 'PCOMPANY') && <li><a class="dropdown-item" href="#/CustomerListScreen">Pickup customer list</a></li>}
                  {(props.authToken.role.name === 'PCOMPANY') && <li><a class="dropdown-item" href="#/CustomerAddScreen">Add pickup customer</a></li>}
                  {(props.authToken.role.name === 'SCOMPANY') && <li><a class="dropdown-item" href="#/SCustomerList">Sales customer list</a></li>}
                  {(props.authToken.role.name === 'SCOMPANY') && <li><a class="dropdown-item" href="#/SCustomer">Add sales customer</a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/ActiveCustomer">Active Pickup Customer</a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/DeletedCustomer">Deleted Pickup Customer</a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/ActiveSCustomer">Active Sales Customer</a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/DeletedSCustomer">Deleted Sales Customer</a></li>}

                </ul>
              </li>
              {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'PCOMPANY' || props.authToken.role.name === 'PEMPLOYEE'|| props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'SEMPLOYEE') && <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Services
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  {(props.authToken.role.name === 'PCOMPANY' || props.authToken.role.name === 'PEMPLOYEE') && <li><a class="dropdown-item" href="#/PickupAddScreen">Add pickup milk </a></li>}
                  {( props.authToken.role.name === 'PCOMPANY' || props.authToken.role.name === 'PEMPLOYEE') && <li><a class="dropdown-item" href="#/PickupListScreen">Pickup milk list</a></li>}
                  {(props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'SEMPLOYEE') && <li><a class="dropdown-item" href="#/AddSales">Add sales milk</a></li>}
                  {( props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'SEMPLOYEE') && <li><a class="dropdown-item" href="#/SelsList"> Sales milk list</a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/AllPickup"> Pickup milk list</a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a class="dropdown-item" href="#/AllSales"> Sales milk list</a></li>}
                </ul>
              </li>}
              <li class="nav-item dropdown">
                {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY' || props.authToken.role.name === 'PCOMPANY') && <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Reports
                </a>}
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'PCOMPANY') && <li><a class="dropdown-item" href="#/MonthelyCollaction">Pickup milk report </a></li>}
                  {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'SCOMPANY') && <li><a class="dropdown-item" href="#/SalseCollactionSummery">Sales milk report</a></li>}

                </ul>
              </li>
              <li class="nav-item dropdown">
                {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'PCOMPANY'|| props.authToken.role.name === 'SCOMPANY') && <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Payment
                </a>}
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  {(props.authToken.role.name === 'Admin' || props.authToken.role.name === 'PCOMPANY' || props.authToken.role.name === 'SCOMPANY') && <li><a class="dropdown-item" href="#/Payment">Payment </a></li>}

                </ul>
              </li>
            </ul>
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown 02" role="button" data-bs-toggle="dropdown" aria-expanded="false">

                  <div className="d-flex hemuicon">
                    <h2 className="hemucon"> {props.authToken.name} <Icon type="down" /></h2>
                    <svg className="user01" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                  </div>


                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" onClick={myaccount}> Account</a></li>
                  <li><a class="dropdown-item" href="#"> Settings</a></li>
                  <li><a class="dropdown-item" onClick={handleLogout}> Log out</a></li>
                  <li><a class="dropdown-item" href="#/Support"> Support<svg className="bellsvg1" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z" /></svg></a></li>

                  {/* <li><a class="dropdown-item1">{diffDays} days left in your trial period</a></li> */}

                  {(props.authToken.role.name === 'Admin') && <li><a onClick={click01}><a class="dropdown-item" href="#/FeedbackList"> Feedback List</a></a></li>}
                  {(props.authToken.role.name === 'Admin') && <li><a onClick={click01}><a class="dropdown-item" href="#/Packages"> Packages List</a></a></li>}
                  <li><a class="dropdown-item 02" href="#/Notification" > Notification <svg className="bellsvg" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z" /></svg></a></li>

                </ul>
                <ul className="bellul" >

                </ul>
              </li>

            </ul>
          </div>
        </div>
      </nav>




    </div>


  );
}