import React, { useEffect, useState, useContext } from "react";
import { Col, Form, Row, Button, Input, message, Select, Modal } from "antd";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { PageTitle } from "../../PageTitle";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const SalsAddScreen = () => {
  let navigate = useNavigate();
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());
  defaultDate.setTime(defaultDate.getTime());
  const user = useContext(UserContext);
  const [routeList, setRouteList] = useState("");
  const [routeList1, setRouteList1] = useState("");
  const [customerList, setCustomerList] = useState("");
  const [vehicleList, setVehicleList] = useState("");
  const [milkType, setMilkType] = useState("");
  const [summery, setSummrey] = useState("");
  const [pickupShift, setPickupShift] = useState("Morning Shift");
  const [uom, setUom] = useState("Litre");
  const [quantity, setQuantity] = useState("");
  const [amount, setAmount] = useState("");
  const [rate, setRate] = useState("");
  const [transactionDate, setTransactionDate] = useState(defaultDate);
  const [fat, setFat] = useState("");
  const [snf, setSnf] = useState("");
  const [routeId, setRouteId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [companyId, setCompanyId] = useState('');
  const [Scustomer, setScustomer] = useState('');
  const [code, setCode] = useState('');
  const [routeCheck, setRouteCheck] = useState(false);




  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);

  const pageLoad = () => {
    getRoute();
    getCustomer();
    getVehicle();
    routeIdCheak()
    setRoute()


    setCompanyId(user.companyId);
  }
  const setRoute = () => {
    if (user.role === "SEMPLOYEE") {
      if (user.route !== 1) {
        setRouteId(user.route);
      }
    }
  }
  const routeIdCheak = () => {
    if (user.role === "SEMPLOYEE") {
      if (user.route === 1) {
        Modal.confirm({
          title: "Plese contect your company and add a route with your id",
          okText: "Yes",
          okType: "danger",
          cancelText: "No",
          onOk() {
            handleLogout();
          },
          onCancel() { },
        });
      }

    }


  }
  const handleLogout = () => {
    localStorage.clear();
    // navigate(`${environment.url.AUTH_URL}`, { replace: true });
    // navigate('/LoginScreen', { replace: true });
    // window.location.href=`${environment.url.AUTH_URL}`;
    window.location.href = `/`;
  }
  const getRoute = () => {
    if (user.role === "SEMPLOYEE") {
      if (user.route !== null && user.route !== undefined) {
        axiosInstance.get(`/route/sales/${user.route}`).then((response) => {
          setRouteList1(response.data.data);
        })
      }
    }
    else {
      axiosInstance.get(`/route/user/${user.companyId}`).then((response) => {
        setRouteList(response.data.data);
        if (response.data.data.length === 0) {
          setRouteId(user.companyId)
          setRouteCheck(true)
        }
      });
    }
  };
  const getCustomer = () => {
    axiosInstance.get(`/scustomer/user/${user.companyId}`).then((res) => {
      setCustomerList(res.data.data);
    });
  };
  const getVehicle = () => {
    axiosInstance.get(`/vehicle/user/${user.companyId}`).then((res) => {
      if (res.data.data.length === 0) {
        const data = {
          name: "Test",
          registrationNo: "Test",
          contactNumber: 1000110001,
          contactPerson: "Test",
          vehcleType: "650d460b18bc8e4f1c2310ef",
          userId: parseInt(user.userId),
          companyId: user.companyId,
        };
        axiosInstance.post(`/vehicle`, data).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            getVehicle();
          }
        })
      }

      setVehicleList(res.data.data);
    });
  };

  var data;

  const onSave = () => {
      data = {
        rate: rate,
        amount: amount,
        uom: uom,
        quantity: quantity,
        milkType: milkType,
        pickupShift: pickupShift,
        transactionDate: new Date(transactionDate),
        routeId: routeId,
        customer: Scustomer._id,
        vehicle: vehicleId,
        companyId: companyId,
      };
    
   




    axiosInstance.post(`/salemilk`, data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
        setSummrey(data);
        navigate('/SelsList', { replace: true });
      } else message.error("Something wrong. Please try again...!");
    });
  };
  const onRouteChange = (e) => {
    setRouteId(e);
    setCustomerId("");
    axiosInstance.get(`/route/sales/${e}`).then((response) => {
      setRouteList1(response.data.data);

    })
  };
  const onCustomeridChange = (e) => {
    setCustomerId(e);
    axiosInstance.get(`/scustomer/${e}`).then((res) => {
      setScustomer(res.data.data);
      setCode(res.data.data.customerCode)
    });
  };

  const onMilktypeChange = (e) => {
    setMilkType(e);
    if (e === "Cow") {
      setRate(Scustomer.crate)
    }
    else {
      if (e === "Buff") {
        setRate(Scustomer.brate)
      }
      else {
        if (e === "Gir") {
          setRate(Scustomer.grate)
        }
        else {
          if (e === "Desi") {
            setRate(Scustomer.drate)
          }

        }
      }

    }
  };

  const onVehicleidChange = (e) => {
    setVehicleId(e);
  };

  const onQuantityChange = (e) => {
    setQuantity(e);

    var amount = parseFloat(e * rate).toFixed(2);
    setAmount(amount);
  };
  const onUOMChange = (e) => {
    setUom(e);
  };

  const onSetDate = (event) => {
    setTransactionDate(new Date(event.target.value));
  };
  const onShiftChange = (e) => {
    setPickupShift(e);
  };
  const onCodeChange = (e) => {
    setCode(e.target.value);
    setCustomerId("")
    setRate("")
    setMilkType("")
    if (e.target.value.length > 2) {
      let cust = customerList.find(x => x.customerCode.toString() === e.target.value.toString())
      setRate("")
      setMilkType("")
      setScustomer(cust)
      setCustomerId(cust._id)
    }


  };
  return (

    <div>
      <div>
        <div className=" text-center main-heading">

          <h1>Add sales</h1>
        </div>
      </div>
      <div className="pickp-add-screen container">

        <Form name="basic">
          <div className="row mb-3 end">

            {routeCheck === false && <div className="col-md-12 text">
              {(user.role === "SCOMPANY" || user.role === "Admin") &&

                <div>
                  <label className="my">Route</label>
                  <Form.Item colon={false} >
                    <Select
                      placeholder="Route"
                      value={routeId}
                      onChange={onRouteChange}
                    >
                      {routeList &&
                        routeList.map((routeList) => (
                          <Option key={routeList._id}>{routeList.name}</Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              }
            </div>}
            <div className="col-md-4 text">
              <label className="my">Code</label>
              <Form.Item colon={false} >
                <Input type="integer"
                  placeholder="Code"
                  autoFocus={true}
                  value={code}
                  onChange={(onCodeChange)}
                />
              </Form.Item>
            </div>
            {routeCheck === false && <div className="col-md-4 text">
              <label className="my">Customer</label>
              <Form.Item colon={false} >
                <Select
                  placeholder="Customer"
                  value={customerId}
                  onChange={onCustomeridChange}
                >
                  {routeList1 &&
                    routeList1 && routeList1[0].scustomers && routeList1[0].scustomers.map((el) => (
                      <Option key={el._id}>{el.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </div>}
            {routeCheck === true && <div className="col-md-4 text">
              <label className="my">Customer</label>
              <Form.Item colon={false} >
                <Select
                  placeholder="Customer"
                  value={customerId}
                  onChange={onCustomeridChange}
                >
                  {customerList &&
                    customerList && customerList.map((el) => (
                      <Option key={el._id}>{el.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </div>}
            <div className="col-md-4 text">
              <label className="my">Milk Type</label>
              <Form.Item colon={false} >
                <Select
                  placeholder="Milk Type"
                  value={milkType}
                  onChange={onMilktypeChange}
                >
                  <Option value={"Cow"}>Cow</Option>
                  <Option value={"Buff"}>Buff</Option>
                  <Option value={"Gir"}>Gir Cow</Option>
                  <Option value={"Desi"}>Desi Cow</Option>

                </Select>
              </Form.Item>
            </div>

            <div className="col-md-4 text">
              <label className="my">Quantity</label>
              <Form.Item colon={false} >
                <Input
                  type="integer"
                  placeholder="Quantity"
                  value={quantity}
                  onChange={(e) => onQuantityChange(e.target.value)}
                />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
              <label className="my">Rate</label>
              <Form.Item colon={false} disabled >
                <Input placeholder="Rate" disabled={true} value={rate} />
              </Form.Item>
            </div>




            <div className="col-md-4 text">
              <label className="my">Amount</label>
              <Form.Item colon={false} >
                <Input placeholder="Amount" disabled={true} value={amount} />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
              <label className="my">UOM</label>
              <Form.Item colon={false} >
                <Select placeholder="UOM" value={uom} onChange={onUOMChange}>
                  <Option key={"Kg"}>Kg</Option>
                  <Option key={"Litre"}>Litre</Option>
                </Select>
              </Form.Item>
            </div>



            <div className="col-md-4 text">
              <label className="my">Pickup Shift</label>
              <Form.Item colon={false} >
                <Select
                  placeholder="Pickup Shift"
                  value={pickupShift}
                  onChange={onShiftChange}
                >
                  <Option key={"Morning Shift"}>Morning Shift</Option>
                  <Option key={"Evening Shift"}>Evening Shift</Option>
                </Select>
              </Form.Item>
            </div>


            <div className="col-md-4 text">
              <label className="my">Vehicle</label>
              <Form.Item colon={false}  >
                <Select
                  placeholder="Vehicle"
                  value={vehicleId}
                  onChange={onVehicleidChange}
                >
                  {vehicleList &&
                    vehicleList.map((vehicleList) => (
                      <Option key={vehicleList._id}>{vehicleList.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-md-4 text">
              <label className="my">Date</label>
              <Form.Item colon={false}  >
                <Input
                  disabled={true}
                  value={transactionDate.toLocaleDateString("CA")}
                  onChange={onSetDate}
                />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
              <div>
                <Button className="btnmy" onClick={onSave}>
                  Save
                </Button>
              </div>
            </div>





          </div>
        </Form>


      </div>
    </div>



  );
};

export default SalsAddScreen;
