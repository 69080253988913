import { Col, Form, Row, Input, Button, message, Modal, Image } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import axiosInstance from "../axiosInstance";
import { UserContext } from "../globalContext";
import logo from "../../src/images/Orbotlogop.png";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import image from "../../src/images/LoginScreen.png"
import offer from "../../src/images/offe.jpg"

import ForgotPasswordModel from "./ForgotPassword.model";

const LoginScreen = () => {
  let navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen1, setIsModalOpen1] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = useContext(UserContext);
  useEffect(() => {

    let mounted = true;
    if (mounted) {

      
      if (user.userId !== null) {
        // navigate("/HomeScreen", { replace: true });
        localStorage.clear();
        window.location.reload();
      }


    }
    return () => (mounted = false);
  }, []);

  const onCancel = () => {
    setIsModalOpen(false);
  };
  const onAdd = () => {
    setIsModalOpen(false);
  };

  const onSignin = () => {

    axiosInstance
      .post("/login", { mobile: mobile, password: password })
      .then((res) => {
       
        if (res.data) {
          if(res.data.responseCode === 1){
            localStorage.setItem("authtoken", JSON.stringify(res.data.auth));

            navigate(`/HomeScreen`, { replace: true });
           // window.location.href = "#/HomeScreen";
  
          }
          if(res.data.responseCode === -2){
            message.error("Your account is temporarily frozen");
          }
          if(res.data.responseCode === -1){
            message.error("please enter correct mobile number or password");
          }

        } else message.error("please enter correct mobile number or password");
      })
      .catch((err) => {
        message.error("please enter correct mobile number or password");
      });
  };
  const onSignup = () => {
    navigate('/SignupScreen', { replace: true });
    // window.location.href= `/SignupScreen`;
  };

  const onForgotPassword = () => {
    setIsModalOpen(true);
    // navigate('/SSignupScreen', { replace: true });

  };

  

  return (
    <div className="bgall">
      <div className="container 01">
        <div className="bgsine">
          <div className="row">
            <div className="col-md-7">
              <div className="bgimg">
                <div className="d-flex align-items-center">
                  <img
                    src={logo}
                    alt=""
                    style={{ width: "100px", paddingTop: "10px" }}
                  />
                  {/* <h2 className="wel">Welcome</h2> */}
                </div>

              </div>
            </div>

            <div className="col-md-5">
              <div className="login-page-form">
                <div>
                  {/* <Col >
              <img
                src={logo}
                alt=""
                style={{ width: "100px", height: "100px" }}
              />
            </Col> */}
                  <div >
                    <h1 className="login-page02"><h1 className="formtag6">log In</h1></h1>
                  </div>
                </div>
                <Form
                  name="basic"
                  labelCol={{

                  }}
                  wrapperCol={{

                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      onSignin();
                    }
                  }}
                >
                  <div >
                    <div >
                      <Form.Item
                        colon={false}
                        label="Mobile number"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            type: "mobile",
                            message: "Please input Valid mobile number!",
                          },
                          {
                            max: "10",
                            message: "Maximum 10 Digits",
                          },
                        ]}
                      >
                        <Input
                          value={mobile}
                          maxLength={10}
                          minLength={10}
                          onChange={(e) => setMobile(e.target.value)}
                          placeholder="Mobile "
                          autoFocus={true}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div >
                    <div >
                      <Form.Item
                        colon={false}
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                      >
                        <Input.Password
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="d-flex but">
                    <div >
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => onSignin()}
                        className="but01"

                      >
                        Log in
                      </Button>
                    </div>
                    <div>
                      <Button type="secondary" onClick={() => onSignup()} className="but02">
                        Sign up
                      </Button>
                    </div>
                  </div>
                  <div>
                    <div className="but03">
                      <Button type="link" onClick={() => onForgotPassword()} >
                        Forgot Password?
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {isModalOpen && <ForgotPasswordModel
              isVisible={isModalOpen}
              onCancel={onCancel}
              onAdd={onAdd}
            />}

            <div>
              <div className="login-right-bg"></div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Diwali special offer"
        open={isModalOpen1}
        onOk={() => setIsModalOpen1(false)}
        onCancel={() => setIsModalOpen1(false)}
        footer={null}
        style={{height:"100vh",width:"100vw"}}
        className="offer"
      >
       <Image src={offer} ></Image>
      </Modal>
      
    </div>
  );
};

export default LoginScreen;
