import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";


const { Option } = Select;
const SelectMember = (props) => {
  const user = useContext(UserContext);

  const [customerList, setcustomer] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [member, setMember] = useState("");

  useEffect(() => {
    getMember();
  }, []);

  const getMember = () => {
    axiosInstance.get(`/member/user/${user.companyId}`).then((res) => {
      var deb = res.data.data.find(x => x.role._id.toString() !== user.roleId.toString());
     const data = {
      roleId:deb.role._id
     }
      axiosInstance.post(`/member/user1/${user.companyId}`,data).then((res) => {
        setcustomer(res.data.data);
      })
    });
  };
  const onAdd = () => {
      const data = {
        route: member
      }
      axiosInstance.put(`/member/update/${customerId}`, data).then((res) => {
        if (res.data && res.data.responseCode === -1) {
          message.error("Record Already Exists");
        } else if (res.data && res.data.responseCode === 1) {
          message.success("Record Update successfully");
          props.hii(false)
        } else message.error("Something wrong. Please try again...!");
      });
    }
     
  
 
  const onCustomeridChange = (e) => {
    setCustomerId(e)
    setMember(props.member)
  };

  return (
    <>
      <div>
        <Modal
          title="Scheme Detail"
          open={props.isVisible}
          onCancel={props.onCancel}
          width={"500px"}
          footer={[
            <Col span={24} className="footer">
              <div>
                <Button key="back" onClick={props.onCancel}>
                  Cancel
                </Button>
                <Button
                  key="submit"
                  onClick={onAdd}
                  style={{
                    background: "rgb(254, 197, 90)",
                    color: "white",
                    fontSize: "15px",
                  }}
                >
                  Ok
                </Button>
                
              </div>
            </Col>,
          ]}
        >
          <Row style={{ paddingTop: "20px" }} className="rowscheme">
            <Col span={16}>
              <Form.Item colon={false} label=" Customer">
                <Select
                  placeholder="Customer"
                  value={customerId}
                  onChange={onCustomeridChange}
                >
                  {/* <Option value='0' >--Select Customer--</Option> */}
                  {customerList &&
                    customerList.map((customerList) => (
                      <Option key={customerList._id}>{customerList.name}</Option>
                    ))}
                </Select>
                
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </div>
    </>
  );
};

export default SelectMember;
