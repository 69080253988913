import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Button, Input, message, Table, Modal, Select } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined,PlusSquareOutlined } from "@ant-design/icons";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { PageTitle } from "../../PageTitle";
import { useNavigate } from "react-router-dom";
import SelectMember from "./AddMemberModel";

const { Option } = Select;
const RouteScreen = () => {
  let navigate = useNavigate();
  const user = useContext(UserContext);
  const [name, setName] = useState("");
  const [routeData, setRouteData] = useState([]);
  const [readRouteObj, setReadRouteObj] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerList, setCustomerList] = useState("");
  const [arrCustomers, setArrCustomers] = useState(["Select Customer"]);
  const [companyId, setCompanyId] = useState('');
  const [IsModelOpen, setisModalOpen] = useState(false);
  const [member, setMember] = useState({});
  const [update, setUpdate] = useState(false);


  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);

  const pageLoad = () => {
    getRoute();
    getCustomer();
    setCompanyId(user.companyId);
  }

  const getCustomer = () => {
    if (user.role === "PCOMPANY" || user.role === "PEMPLOYEE"|| user.role === "Admin") {
      axiosInstance.get(`/customer/user/${user.companyId}`).then((res) => {
        setCustomerList(res.data.data);
      });
    }
    else {
      if (user.role === "SCOMPANY" || user.role === "SEMPLOYEE" ) {
        axiosInstance.get(`/scustomer/user/${user.companyId}`).then((res) => {
          setCustomerList(res.data.data);
        });

      }

    }

  };
  const onCancel = () => {
    setisModalOpen(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Customers",
      dataIndex: "customers",
      key: "customers",
      render: (customers) => {
        return <div>{customers.length}</div>;
      },
    },
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
          <>
            <EyeOutlined
              onClick={() => {
                readRoute(row);
              }}
            />
            <EditOutlined
              style={{ marginLeft: 12 }}
              onClick={() => {
                modify(row);
              }}
            />
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removeRoute(row);
              }}
            /> <Button
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                ADD(row);
              }}
            >Add employee</Button>

          </>
        );
      },
    },
  ];
  const columns1 = [
    
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Customers",
      dataIndex: "scustomers",
      key: "scustomers",
      render: (scustomers) => {
        return <div>{scustomers.length}</div>;
      },
    },
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
          <>
            <EyeOutlined
              onClick={() => {
                readRoute(row);
              }}
            />
            <EditOutlined
              style={{ marginLeft: 12 }}
              onClick={() => {
                modify(row);
              }}
            />
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removeRoute(row);
              }}
            /> <Button
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                ADD(row);
              }}
            >Add employee</Button>

          </>
        );
      },
    },
  ];

  const getRoute = () => {
    axiosInstance.get(`/route/user/${user.companyId}`).then((response) => {
      setRouteData(response.data.data);
    });
  };
  const readRoute = (obj) => {
    setReadRouteObj(obj);
    setIsModalOpen(true);
  };
  const modify = (obj) => {
    navigate('RouteEditScreen/${obj.id}', { replace: true });
  };
  var rname;
  const onSave = () => {
    if (user.role === "PCOMPANY" || user.role === "PEMPLOYEE") {
      rname = "P" + name
    }
    else {
      rname = "S" + name
    }
    const data = {
      role: user.role,
      name: rname,
      companyId: companyId,
      userId: parseInt(user.userId),
      customers: arrCustomers,
      scustomers: arrCustomers,
    };
    // if(user.role==="Admin"||user.role==="SCOMPANY"){
    //   data.customers=""
    // }
    // else{
    //   if(user.role==="PCOMPANY"){
    //   data.scustomers=""

    //   }
    // }
    
    axiosInstance.post(`/route`, data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
        setArrCustomers('');
        setName('');
        getRoute();
        navigate('RouteScreen', { replace: true });
      } else message.error("Something wrong. Please try again...!");
    });
  };

  const removeRoute = (obj) => {
    Modal.confirm({
      title: "Do you want to remove this Member?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosInstance.delete(`/route/${obj.id}`).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            message.success("Record Deleted successfully");
            getRoute();
          } else message.error("Something wrong. Please try again...!");
        });
      },
      onCancel() { },
    });
  };

  const ADD =  (obj) => {
    setMember(obj._id)
    if(member===undefined&&member===null){
      ADD()
    }
    else{
      setisModalOpen(true)
    }

     
   };


  const onNameChange = (e) => {
    setName(e.target.value)
  }
  const handleAddClick = () => {
    let tempArrCustomer = arrCustomers;
    tempArrCustomer.push("");

    setArrCustomers([...tempArrCustomer]);
  };
  const handleDelete = (index) => {
    const filterUsers = [...arrCustomers];
    filterUsers.splice(index, 1);
    setArrCustomers(filterUsers);
  };

  const handleOnChange = (e, i) => {

    let tempArrCustomer = arrCustomers;
    tempArrCustomer[i] = e;

    setArrCustomers([...tempArrCustomer]);


  };
  return (
    <div>
      <div>
        <div className="text-center main-heading">
          {/* <PageTitle title="Route Screen"></PageTitle> */}
          <h1>Route Screen</h1>
        </div>
      </div>
      <SelectMember
        isVisible={IsModelOpen}
        onCancel={onCancel}
        member={member}
        update={setUpdate}
        hii={setisModalOpen}

      />
     <div className="route-screen container">
    

      <div className="row mb-3 start">
        <div className="col-md-4 text">
          <div>
            <label className="my">Route Name</label>
            <Input
              placeholder="Route Name"
              value={name}
              onChange={(e) => onNameChange(e)}
            />
          </div>
        </div>
        <div className="col-md-4 text">
          <div className="">
            {/* <label>Add Customer</label> */}
            {arrCustomers.map((c, index) => {
              return (
                <div key={index}>
                  <div>
                    <label className="my">Customer {index + 1}</label>
               <div className="d-flex">
               <div className="d-flex">
                <div >
                <Select
                  className="01raj"
                      placeholder="Customer"
                      key={c}
                      value={c}
                      onChange={(e) => handleOnChange(e, index)}
                    >
                      {customerList &&
                        customerList.map((cl) => (
                          <Option key={cl._id}>{cl.name}</Option>
                        ))}
                    </Select>
                </div>
              
                
                     
                </div>
                <div>
            <PlusSquareOutlined  className="btnmy addraj" onClick={handleAddClick}/>
         

          </div>
          <div className="addraj01">
                    {index !==0 ? (
                 
                  <DeleteOutlined className="btnmy addraj02"  onClick={handleDelete}/>

                    ) : (
                      ""
                    )
                  }

                  </div>
               </div>
                  </div>
                </div>
              );
            })}
         
  
            
      
          

            
          </div>
        </div>
        <div  className="col-md-4">
        <div className="start">
               <Button className="btnmy" onClick={onSave}>
                 Save
               </Button>
             </div>
        </div>
       
      </div>

      <div>
       {(user.role==="SCOMPANY")&& <Table rowKey="id" columns={columns1} dataSource={routeData} />}
       {(user.role==="Admin" ||user.role==="PCOMPANY")&& <Table rowKey="id" columns={columns} dataSource={routeData} />}
      </div>
      <Modal
        title={readRouteObj.name}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>Customer Name</th>
              </tr>
            </thead>
            {(user.role==="PCOMPANY")&&<tbody>
              {readRouteObj.customers &&
                readRouteObj.customers.map((cust, index) => {
                  return (
                    <tr key={index}>
                      <td>{cust.name}</td>
                    </tr>
                  );
                })}
            </tbody>}
            {(user.role==="SCOMPANY")&&<tbody>
              {readRouteObj.customers &&
                readRouteObj.scustomers.map((cust, index) => {
                  return (
                    <tr key={index}>
                      <td>{cust.name}</td>
                    </tr>
                  );
                })}
            </tbody>}
          </table>
        </div>
      </Modal>
    </div>
   </div>
  );
};

export default RouteScreen;
