import { Button, message, Row, Col, Input,Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import jsonToPivotjson from "json-to-pivot-json";
import unpivotJson from "../../shared/JsonUnpivot";
import { ReactTable } from "../../shared/ReactTable";
import { PageContext } from "./service";
import { PageTitle } from "../../PageTitle";
import { UserContext } from "../../globalContext";
var Loader = require('react-loader')
const FatsnfRateMatrix = () => {
  const context = useContext(PageContext);
  const user = useContext(UserContext);
  const [companyId, setCompanyId] = useState('');


  let dataObj = [];
  const [dataSource, setDataSource] = useState(dataObj);
  const [tempPriceData, setTempPriceData] = useState(dataObj);
  const [FatValue, setFatValue] = useState('');
  const [BonusValue, setBonus] = useState('');
  const [table, setTable] = useState(false);
  const [btn, setBtn] = useState(false);
  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);

  const pageLoad = () => {
    getData();
    setCompanyId(user.companyId);
  }

  const getData = () => {
    axiosInstance.get(`/fatsnfRateMatrix/user/${user.companyId}`).then((response) => {
      if (response.data && response.data.responseCode === 1) {
        var options = {
          row: "fat",
          column: "snf",
          value: "rate",
        };
        setTempPriceData(response.data.data)
        var output = jsonToPivotjson(response.data.data, options);
        setDataSource(output);
        setTable(true)
      }
      else{
        message.error("Data not found")
        setTable(true)

      }
     
    });
  };

  const getColumns = () => {
    var clist = [
      {
        Header: "Fat/Snf",
        accessor: "fat",
      },
    ];


  var cl = Object.keys(dataSource.length > 0 && dataSource[0]).map((key) => {
      if (key !== "fat") {
        clist.push({
          Header: key,
          accessor: key.toString(),
          Cell: (params) => {
            let datakey = params.row.original.fat + "-" + key;



            return (
              <div>
                <Input
                  data-index={datakey}
                  type="text"
                  className="form-control"
                  defaultValue={params.row.original[key]}
                  onChange={(e) =>
                    onRateChange(e, params.row.original.fat, key)
                  }
                />
              </div>
            );
          },
        });

        return {
          Header: key,
          accessor: key,
        };
      }
    });

    return clist;
  };

  const columns = getColumns();
  const onRateChange = (e, f, s) => {
    let tempds = dataSource;
    tempds.find((d) => d.fat === f)[s] = e.target.value;
    setDataSource(tempds);
  };

  const onHandleSave = (value, f, s) => {
    let unPivotData = unpivotJson(dataSource, { column: "fat" });
    axiosInstance
      .put(`/fatsnfRateMatrix/${companyId}/${parseInt(user.userId)}`, unPivotData)
      .then((res) => {
        if (res.data && res.data.responseCode === -1) {
          message.error("Record Already Exists");
        } else if (res.data && res.data.responseCode === 1) {
          message.success("Record Update successfully");
          setBtn(false);
          setFatValue("")
          setBonus("")
        } else message.error("Something wrong. Please try again...!");
      });
  };
  
  const onAutoGeneratePrice = () => {
   if(FatValue!==undefined  && BonusValue!==undefined&&FatValue!==''&& BonusValue!==''){
    
    setBtn(true);
    let price = parseFloat(FatValue);
    let bonus = parseFloat(BonusValue);
    tempPriceData.map((el, index) => {
      if (el.fat < 5 && el.snf >= 8.5) {
        el.rate = parseFloat((el.fat * price) + bonus).toFixed(2);
      }
      else {
        if (el.fat < 5 && el.snf === 8.4) {
          el.rate = parseFloat((el.fat * price) + bonus-0.5).toFixed(2);
        }
        else {
          if (el.fat < 5 && el.snf === 8.3) {
            el.rate = parseFloat((el.fat * price) + bonus-1).toFixed(2);
          }
          else {
            if (el.fat < 5 && el.snf === 8.2) {
              el.rate = parseFloat((el.fat * price) + bonus-1.5).toFixed(2);
            }
            else {
              if (el.fat < 5 && el.snf === 8.1) {
                el.rate = parseFloat((el.fat * price) + bonus-2).toFixed(2);
              }
              else {
                if (el.fat < 5 && el.snf === 8) {
                  el.rate = parseFloat((el.fat * price) + bonus-2.5).toFixed(2);
                }
                else {
                  if (el.fat >= 5 && el.snf === 9) {
                    el.rate = parseFloat((el.fat * price) + bonus).toFixed(2);
                  }
                  else {
                    if (el.fat >= 5 && el.snf === 8.9) {
                      el.rate = parseFloat((el.fat * price) + bonus-0.5).toFixed(2);
                    }
                    else {
                      if (el.fat >= 5 && el.snf === 8.8) {
                        el.rate = parseFloat((el.fat * price) + bonus-1).toFixed(2);
                      }
                      else {
                        if (el.fat >= 5 && el.snf === 8.7) {
                          el.rate = parseFloat((el.fat * price) + bonus-1.5).toFixed(2);
                        }
                        else {
                          if (el.fat >= 5 && el.snf === 8.6) {
                            el.rate = parseFloat((el.fat * price) + bonus-2).toFixed(2);
                          }
                          else {
                            if (el.fat >= 5 && el.snf <8.6) {
                              el.rate = parseFloat((el.fat * price) + bonus-2.5).toFixed(2);
                                                 
      }}}}}}}}}}}}})

    var options = {
      row: "fat",
      column: "snf",
      value: "rate",
    };
    var output = jsonToPivotjson(tempPriceData, options);
    setDataSource(output);
   }
   else{
    message.error("Please fill out all required fields. And generate again...!")
   }
  }
  return (
    <div >
        <div>
        <div className="text-center main-heading">
          <h1>Rate Matrix</h1>
        </div>
      </div>
      <div className="masters-rate-matrix container">
    
     <div className="row mb-3 end">
     <div className="col-md-4 text">
      <label className="my">Add fat value - </label>

        <div>
          <Input 
          placeholder="Add fat value"
           onChange={(e) => setFatValue(e.target.value)}></Input>
        </div>
        </div>
      <div className="col-md-4 text">
      <label className="my">Bonus value - </label>
        <div>
          <Input 
          placeholder="Add bonus "
          onChange={(e) => setBonus(e.target.value)}></Input>
        </div>
        </div>
      <div className="col-md-4 text">
        <div>
          {btn===false&&<Button className="btnmy" onClick={onAutoGeneratePrice}>Generate</Button>}
         {btn===true&& <Button
               className="btnmyret" 
               type="primary 02"
              onClick={onHandleSave}
              
            >
              Save
            </Button>}
        </div>
      </div>
     </div>
    
     {/* <div>
       {(table==true) && <Row gutter={24}>
          <Col span={24}>
            <Button
               className="btnmy" 
               type="primary 02"
              onClick={onHandleSave}
              style={{ float: "right" }}
            >
              Save
            </Button>
          </Col>
        </Row>}
      </div> */}
    {(table==false) && <Loader loaded={false} shadow={false}>
      
      </Loader>}
<div className="text-center  col-12">
      <div class="table-responsive">
        <div class="table">
       {(table==true) && <ReactTable
          allowPaging={false}
          context={context}
          columns={columns}
          data={dataSource}
          
        ></ReactTable> }
        </div>
      </div>
      </div>
     
    </div>
    </div>
  );
};

export default FatsnfRateMatrix;