import { Form, Row, Input, Button, message, Select, InputNumber } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../axiosInstance";
import { UserContext } from "../globalContext";
import { useNavigate } from "react-router-dom";
import logo from "../../src/images/Orbotlogop.png";
import Otpvalidation from "./OtpValidation.model";
import { Radio } from "antd";
//import otpGenerator from "otp-generator"

const plainOptions = ['Milk Distribution', 'Milk collection'];

const { Option } = Select;


const SignupScreen = (props) => {
  let navigate = useNavigate();
  const [RoleList, setRoleList] = useState();
  const [Role, setRole] = useState();
  const [fullname, setFullname] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [memberReferCode, setMemberReferCode] = useState();
  const [referCode, setReferCode] = useState();
  const [password, setPassword] = useState();
  const [address, setAddress] = useState();
  const [companyname, setcompanyname] = useState();
  const [defaultRole, setDefaultRole] = useState('');
  const [defaultrole, setDefaultrole] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [Otp, setOtp] = useState(1);
  const [MOtp, setMOtp] = useState(false);
  const [isModelOpen, setIsModalOpen] = useState(false);
  const [emailverified, setEmailVerified] = useState(false);
  const [value1, setValue1] = useState('Apple');
  const [value2, setValue2] = useState('Apple');

  const user = useContext(UserContext);
  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);

  const pageLoad = () => {
    if (user.role != null) {
      getRole()
      Reload()
      //generateOTP()
    };
    setCompanyId(user.companyId);
  }
  const Reload = () => {
    if (props.authToken != null) {
      localStorage.clear();
      window.location.reload(true);
    }

  }
  const getRole = () => {
    axiosInstance.get("/role").then((response) => {
      setRoleList(response.data.data);
      if (user.role === "PCOMPANY") {
        let droleObj = response.data.data.find(x => x.name === 'PEMPLOYEE');
        setRole(droleObj._id);

      }
      else {
        if (user.role === "SCOMPANY") {
          let dslectRoleobj = response.data.data.find(x => x.name === 'SEMPLOYEE');
          setRole(dslectRoleobj._id);
        }
      }




    });
  };
  const onRoleidChange = (e) => {
    setRole(e);
  };
  const onCompanychange = (e) => {
    setcompanyname(e.target.value);

  };
  var existMatch = ''
  const onCheakOtp = async (e) => {
    if (e.target.value.length === 6) {
      await axiosInstance.get("/email").then((response) => {
        existMatch = response.data.data.find(x => x.email === email);
      });
      if (e.target.value === existMatch.otp) {
        setMOtp(true)
        message.success("Otp successfully");
      }
      else {
        setMOtp(false)
        message.error("Wrong");

      }
    }
  };

  const onSignin = (e) => {
    navigate(`/`, { replace: true });

  };
  const onOtp = () => {
    axiosInstance.post("/sms/send", { mobile: mobile }).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Otp Sent Fail");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Otp Sent successfully");
        setIsModalOpen(true);
        setMOtp(false)
      } else message.error("Something wrong. Please try again...!");
    });


  };
  const onCancel = () => {
    setIsModalOpen(false);
  };
  const onAdd = () => {
    setIsModalOpen(false);
  };
  const onChange1 = ({ target: { value } }) => {
    setValue1(value);
    axiosInstance.get("/role").then((response) => {
      setRoleList(response.data.data);
      if (value === "Milk Distribution") {
        let droleObj = response.data.data.find(x => x.name === 'SCOMPANY');
        setRole(droleObj._id);

      }
      else {
        let droleObj = response.data.data.find(x => x.name === 'PCOMPANY');
        setRole(droleObj._id);

      }


    });
  }
  const onSignup = () => {
    if (MOtp === true) {
      const data = {
        email: email,
        name: fullname,
        mobile: mobile,
        password: password,
        address: address,
        companyName: companyname,
        role: Role,
        companyId: companyId,
        referCode: referCode,
        registerDate:new Date()
      };
       if(data.role!==undefined  && data.companyName!==undefined&& data.name!==undefined&& data.password!==undefined&& data.email!==undefined&& data.address!==undefined&& data.companyName!==''&& data.name!==''&& data.password!==''&& data.email!==''&& data.address!==''){
        axiosInstance.post("/member", data).then((res) => {
          if (res.data && res.data.responseCode === -1) {
            message.error("Record Already Exists");
          } else if (res.data && res.data.responseCode === 1) {
            message.success("Record saved successfully");
            navigate(`/LoginScreen`, { replace: true });
          } else message.error("Something wrong. Please try again...!");
        });
     }
     else{
      message.error("Please fill out all required fields. And submit again...!");
     }
    }
    else {
      message.error("Plese Varify Your Otp");
    }
  };
  const onEmailChange = (input) => {
    setEmail(input.target.value)
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-].+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.target.value.match(validRegex)) {

      //alert("Valid email address!");

      // document.form1.text1.focus();

      setEmailVerified(true);

    }
    else {

      // alert("Invalid email address!");

      document.form1.text1.focus();

      setEmailVerified(false);

    }

  }

  return (
    <div className="bgall">
      <div className="container 01">
        <div className="bgsine">
          <Row className="row">
            <div className="col-md-7">
              <div className="bgimg">
                <div className="d-flex align-items-center">
                  <img
                    src={logo}
                    alt=""
                    style={{ width: "100px", paddingTop: "10px" }}
                  />
                  {/* <h2 className="wel">Welcome</h2> */}
                </div>

              </div>
            </div>




            <div className="col-md-5">
              <div className="login-page-form">
                <div >
                  {user.role !== null && user.role !== undefined && <h2 className="formtag6 2">Add member</h2>}
                  {!user.role && <h2 className="formtag6 2">Sign up</h2>}
                </div>

                <Form

                >
                  {user.role === "Admin" && <div>
                    <div >
                      <Form.Item colon={false} label=" Role">
                        <Select
                          placeholder="Role"
                          value={Role}
                          onChange={onRoleidChange}
                          rules={[
                            {
                              required: true,
                              message: "Please selectRole!",
                            },
                          ]}
                          autoFocus={true}
                        >
                          {RoleList &&
                            RoleList.map((RoleList) => (
                              <Option key={RoleList._id}>{RoleList.name}</Option>
                            ))}

                        </Select>

                      </Form.Item>
                    </div>
                  </div>}
                  {!user.role && <div>
                    <Form.Item colon={false} label=" Select your company  type"
                      name="Role"
                      rules={[
                        {
                          required: true,
                          message: "Please select your using type!",
                        },
                      ]}>
                      <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <Radio.Group style={{ width: "100%", display: "flex", justifyContent: "center" }} options={plainOptions} onChange={onChange1} value={value1} />
                      </div>
                    </Form.Item>

                  </div>}
                  <div>
                    <div >
                      {(user.companyId === undefined) &&
                        <Form.Item
                          colon={false}
                          label="Company name"
                          name="companyname"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Company Name!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Company Name"
                            onChange={onCompanychange}
                            value={companyname}
                            name="companyname"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Company Name!",
                              },
                            ]}
                            autoFocus={true}
                          />
                        </Form.Item>
                      }
                    </div>
                  </div>
                  <div>
                    <div>
                      <Form.Item
                        colon={false}
                        label="Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your name!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Name"
                          onChange={(e) => setFullname(e.target.value)}
                          value={fullname}
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your name!",
                            },
                          ]}
                          autoFocus={true}
                        />
                      </Form.Item>
                    </div>
                  </div>

               
                  <div>
                    <div>
                      <Form.Item
                        colon={false}
                        label="Address"
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Address!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Address"
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div >
                    <div >
                      <Form.Item
                        colon={false}
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            type: "email",
                            message: "Please input valid email!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Email"
                          // onChange={(e) => setEmail(e.target.value)}
                          onChange={(e) => onEmailChange(e)}
                          value={email}
                        />
                      </Form.Item>


                    </div>
                    
                  </div>

                  <div className="row d-flex center" style={{ width: "100%" }}>
                    <div style={{ width: "60%" }}>
                      <Form.Item
                        colon={false}
                        label="Mobile"
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Mobile!",
                          },
                          {
                            max: "10",
                            message: "Maximum 10 Digits",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Mobile"
                          maxLength="10"
                          minLength="10"
                          onChange={(e) => setMobile(e.target.value)}
                          value={mobile}
                        />
                      </Form.Item>
                    </div>
                   {(MOtp === false) && <div style={{ width: "40%",marginTop:"25px" }}>
                      <Button className="btnmy" onClick={() => onOtp()}>
                        Gen. Otp
                      </Button>
                    </div>}
                   {(MOtp === true) && <div style={{ width: "40%" }}>
                      <Button className="btnmy4" >
                      <svg className="veryfied" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg> Veryfied
                      </Button>
                    </div>}
                  </div>
                  <div>
                    <div>
                      <Form.Item
                        colon={false}
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                      >
                        <Input.Password
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* <div>
          <div>
            <Form.Item
              colon={false}
              label="Refer code"
            >
              <Input
                    
                    value={referCode}
                    onChange={(e) => setReferCode(e.target.value)}
                    placeholder="Enter refer code"
                    
                  />
            </Form.Item>
          </div>
        </div> */}
                  <div
                    style={{ alignItems: "flex-end", marginLeft: "65%", marginBottom: "-7%", }}
                  >

                    <Otpvalidation
                      isVisible={isModelOpen}
                      onCancel={onCancel}
                      onAdd={onAdd}
                      onCheakOtp={onCheakOtp}
                      mobile={mobile}
                      setMOtp={setMOtp}
                      setIsModalOpen={setIsModalOpen}
                      setOtp={setOtp}
                    />

                  </div>
                  <div className="d-flex but">
                    <div className="d-flex but">

                      {user.role !== null && user.role !== undefined &&
                        <Button htmlType="submit" onClick={() => onSignup()} className="btnmy">
                          Add member
                        </Button>}
                      <div className="d-flex center">
                        {!user.role &&
                          <Button type="primary" htmlType="submit" onClick={() => onSignup()} className="but01">
                            Sign up
                          </Button>}
                        {!user.role &&
                          <h6 type="primary" htmlType="submit" onClick={() => onSignin()} className="rajj">
                            Sign in if already a user ?
                          </h6>}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SignupScreen;

// 