import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { useParams } from "react-router-dom";
import { Col, Form, Row, Input, Button, message } from "antd";
import { PageTitle } from "../../PageTitle";
import { useNavigate } from "react-router-dom";

const MemberEditScreen = () => {
  let navigate = useNavigate();
  let { id } = useParams();

  var memberObj = {
    id: id,
    email: "",
    mobile: "",
    name: "",
    address: "",
    password: "",
  };
  const [memberData, setMemberData] = useState(memberObj);

  useEffect(() => {
    let mounted = true;
    if (mounted) onReadMember(id)
;
    return () => (mounted = false);
  }, []);
  const onReadMember = (id) => {
 
    axiosInstance.get(`/member/${id}`).then((response) => {
      setMemberData(response.data.data);
    });
  };

  const onUpdateMember = () => {
    axiosInstance.put(`/member/update/${id}`, memberData).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Mobile number already exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("record updated successfully");
        navigate("/MemberListScreen", { replace: true });
      } else message.error("Something wrong. Please try again...!");
    });
  };

  const onEmailChange = (e) => {
    setMemberData({ ...memberData, email: e.target.value });
  };
  const onNameChange = (e) => {
    setMemberData({ ...memberData, name: e.target.value });
  };
  const onMobileChange = (e) => {
    setMemberData({ ...memberData, mobile: e.target.value });
  };
  const onAddressChange = (e) => {
    setMemberData({ ...memberData, address: e.target.value });
  };
  const onPasswordChange = (e) => {
    setMemberData({ ...memberData, password: e.target.value });
  };
  return (
   <div>
       <div>
        <div className=" text-center main-heading">
          {/* <PageTitle title="Edit Member"></PageTitle> */}
          <h1>Edit Member</h1>
        </div>
      </div>
     <div className="member-edit-screen container">
   

      <Form
        name="basic"
    
      >
<div className="row mb-3 end">
<div  className="col-md-4 text">
          <div>
          <label className="my">Name</label>
            <Form.Item colon={false}>
              <Input
                placeholder="Name"
                onChange={onNameChange}
                value={memberData.name}
              />
            </Form.Item>
          </div>
        </div>
        <div  className="col-md-4 text">
          <div>
          <label className="my">Email</label>
            <Form.Item colon={false} >
              <Input
                placeholder="Email"
                onChange={onEmailChange}
                value={memberData.email}
              />
            </Form.Item>
          </div>
        </div>
        {/* <Row gutter={20}>
          <Col lg={12} md={15} sm={19} xs={22}>
            <Form.Item colon={false} label="password">
              <Input
                placeholder="password"
                onChange={onPasswordChange}
                value={memberData.password}
              />
            </Form.Item>
          </Col>
        </Row> */}
        <div  className="col-md-4 text">
          <div>
          <label className="my">Mobile</label>
            <Form.Item colon={false}>
              <Input
                placeholder="Mobile"
                onChange={onMobileChange}
                value={memberData.mobile}
              />
            </Form.Item>
          </div>
        </div>

        <div  className="col-md-4 text">
          <div>
          <label className="my">Address</label>
            <Form.Item colon={false}  className="raj0101">
              <Input
                placeholder="Address"
                onChange={onAddressChange}
                value={memberData.address}
              />
            </Form.Item>
          </div>
        </div>

        <div  className="col-md-4 text">
          <div>
      
            <Button className="btnmy" onClick={() => onUpdateMember()}>
              Update
            </Button>
          </div>
        </div>
</div>
      </Form>
    </div>
   </div>
  );
};
export default MemberEditScreen;