import { Col, Form, Row, Button, Input, message, Table, Modal,Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { PageTitle } from "../../PageTitle";
import moment from "moment";

const { Option } = Select;
const Payment = () => {
    let defaultDate = new Date();
  const user=useContext(UserContext)
  const [debit, setDebit] = useState("");
  const [credit, setCredit] = useState("");
  const [PaymentData, setPaymentData] = useState([]);
  const [readSnfObj, setReadSnfObj] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customer, setCustomer] = useState('');
  const [transactionDate, setTransactionDate] = useState(defaultDate);


  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);
  
  const pageLoad = () => {
    getPayment();
    getCustomer();
    onSetDate();
    setCompanyId(user.companyId );
  }
  const getCustomer = () => {
    if(user.role==="PCOMPANY"){
      axiosInstance.get(`/customer/user/${user.companyId}`).then((response) => {
        setCustomer(response.data.data);
    });
    }
    else{
      axiosInstance.get(`/scustomer/user/${user.companyId}`).then((response) => {
        setCustomer(response.data.data);
    });

    }
   
  };

  const onSetDate = () => {
    setTransactionDate(new Date());
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
        title: "Name",
        render: (row) => {
            return row.customer ? (
              <div>
    
              { row&& <b>{row.customer.name}</b>}<br/>
            
                </div>
            ) : (
              <div> </div>
            );
          },
      },
    {
        title: "Debit",
        dataIndex: "debit",
        key: "debit",
      },
      //  {
      //   title: "Credit",
      //   dataIndex: "credit",
      //   key: "credit",
      // },
      {
        title: "Transaction Date",
        render: (row) => {
            return(
              <>
              {row && <div>{moment(row.transactionDate).format("DD-MM-yyyy")}</div>}
              </>
            );
          },
      },
    {
      title: "Company Name",
      render: (row) => {
        return(
          <>
          {row.companyId?.name}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
    //       <>
    //         <EyeOutlined
    //           onClick={() => {
    //             readPayment(row);
    //           }}
    //         />
    //         <EditOutlined
    //           style={{ marginLeft: 12 }}
    //           // onClick={() => {
    //           //   modify(row);
    //           // }}
    //         />
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removePayment(row);
              }}
            />
    //       </>
        );
      },
    },
  ];
  const columns1 = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
        title: "Name",
        render: (row) => {
            return row.scustomer ? (
              <div>
    
              { row&& <b>{row.scustomer.name}</b>}<br/>
            
                </div>
            ) : (
              <div> </div>
            );
          },
      },
    // {
    //     title: "Debit",
    //     dataIndex: "debit",
    //     key: "debit",
    //   },
       {
        title: "Credit",
        dataIndex: "credit",
        key: "credit",
      },
      {
        title: "Transaction Date",
        render: (row) => {
            return(
              <>
              {row && <div>{moment(row.transactionDate).format("DD-MM-yyyy")}</div>}
              </>
            );
          },
      },
    {
      title: "Company Name",
      render: (row) => {
        return(
          <>
          {row.companyId?.name}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
    //       <>
    //         <EyeOutlined
    //           onClick={() => {
    //             readPayment(row);
    //           }}
    //         />
    //         <EditOutlined
    //           style={{ marginLeft: 12 }}
    //           // onClick={() => {
    //           //   modify(row);
    //           // }}
    //         />
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removePayment(row);
              }}
            />
    //       </>
        );
      },
    },
  ];

  const getPayment = () => {
    axiosInstance.get(`/payment/user/${user.companyId}`).then((response) => {
      console.log("payment",response.data.data)
      setPaymentData(response.data.data);
    });
  };

  const readPayment = (obj) => {
    axiosInstance.get(`/snf/${obj.id}`).then((response) => {
      setReadSnfObj(response.data.data);
    });
    setIsModalOpen(true);
  };
  const onSave = () => {
 if(user.role==="PCOMPANY"){
  var data = {
    debit: debit,
  credit: credit,
  companyId: companyId,
  customer: customerId,
  userId:parseInt(user.userId),
  transactionDate: new Date(transactionDate),
};
 }
 else{
  var data = {
    debit: debit,
  credit: credit,
  companyId: companyId,
  scustomer: customerId,
  userId:parseInt(user.userId),
  transactionDate: new Date(transactionDate),
};
 }
    axiosInstance.post(`/payment`, data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
        getPayment();
      } else message.error("Something wrong. Please try again...!");
    });
  };

  const removePayment = (obj) => {
    Modal.confirm({
      title: "Do you want to remove this Member?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosInstance.delete(`/payment/${obj.id}`).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            message.success("Record Deleted successfully");
            getPayment();
          } else message.error("Something wrong. Please try again...!");
        });
      },
      onCancel() {},
    });
  };
  const onCustomeridChange = (e) => {
    setCustomerId(e);
  };
  return (
   <div>
       <div>
      <div className="text-center main-heading">
      {/* <PageTitle title="Snf Screen">
        </PageTitle> */}
        <h1>Payment Screen</h1>
        </div>
      </div>
     <div className="master-snf-screen container">
    
      
      <div>
        <Form name="basic"
          
           >
          <div className="row mb-3 end">
          <div className="col-md-4 text">
          <label className="my">Customer</label>
        <Form.Item colon={false} className="raj0101">
                <Select
                  placeholder="Customer"
                  debit={customerId}
                  onChange={onCustomeridChange}
                >
                  {customer &&
                    customer && customer.map((el) => (
                      <Option key={el._id}>{el.name}</Option>
                    ))}
                </Select>
              </Form.Item>
        </div>
           { (user.role==="PCOMPANY")&&<div className="col-md-4 text">
              <Form.Item colon={false}  className="raj0101">
                <label className="my">Debit</label>
                <Input
                  placeholder="Debit"
                  debit={debit}
                  onChange={(e) => setDebit(e.target.value)}
                />
              </Form.Item>
            </div>}
            {(user.role==="SCOMPANY")&& <div className="col-md-4 text">
              <Form.Item colon={false}  className="raj0101">
                <label className="my">Credit</label>
                <Input
                  placeholder="Credit"
                  debit={credit}
                  onChange={(e) => setCredit(e.target.value)}
                />
              </Form.Item>
            </div> }
            <div className="col-md-4 text">
              <Button className="btnmy" onClick={onSave}>
                Save
              </Button>
            </div>
          </div>
          
        </Form>
      </div>
      <div>
        {(user.role==="PCOMPANY")&&<Table columns={columns} dataSource={PaymentData} />}
        {(user.role==="SCOMPANY")&&<Table columns={columns1} dataSource={PaymentData} />}
      </div>
      
      <Modal
        title="SNF-List"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <ul className="list-group w-50">
          <li className="list-group-item"> ID : {readSnfObj.id}</li>
          <li className="list-group-item"> debit : {readSnfObj.debit}</li>
          <li className="list-group-item"> debit : {readSnfObj.credit}</li>
          <li className="list-group-item"> Company Id : {readSnfObj.companyId?.name}</li>
          <li className="list-group-item"> User Id : {readSnfObj.userId}</li>
         
        </ul>
      </Modal>
    </div>
   </div>
  );
};

export default Payment;
