import React from "react";

const Support = () => {


  return (
   <div>
   <div className=" text-center main-heading">
          {/* <PageTitle title=""></PageTitle> */}
          <h1>Contact us</h1>
        </div>
    <div class="container">
<div className="row">



<div class="card01 col-md-4">
  
  <div class="card-body support">
  <a href="mailto:info@orbotsoftwares.com">
  <div class="contact-info">
                    <div class="contact-info-item">
                      <div class="contact-info-icon">
                      <svg className="email01" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM218 271.7L64.2 172.4C66 156.4 79.5 144 96 144H352c16.5 0 30 12.4 31.8 28.4L230 271.7c-1.8 1.2-3.9 1.8-6 1.8s-4.2-.6-6-1.8zm29.4 26.9L384 210.4V336c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V210.4l136.6 88.2c7 4.5 15.1 6.9 23.4 6.9s16.4-2.4 23.4-6.9z"/></svg>
                      </div>
                      <div class="contact-info-text">
                        <h2>E-mail</h2>
                        <h3 className="Support">info@orbotsoftwares.com</h3>
                        
                        <span>Milkvan</span> 
                       
                      </div>
                    </div>            
                  </div>
  </a>
  </div>
</div>
<div class="card01 col-md-4">
  
  <div class="card-body support">
  <a href="https://api.whatsapp.com/send?phone=+919571782430&amp;text=Hello team milkvan" target="_blank">
  <div class="contact-info">
                    <div class="contact-info-item">
                      <div class="contact-info-icon">
                      <svg className="email01" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224 122.8c-72.7 0-131.8 59.1-131.9 131.8 0 24.9 7 49.2 20.2 70.1l3.1 5-13.3 48.6 49.9-13.1 4.8 2.9c20.2 12 43.4 18.4 67.1 18.4h.1c72.6 0 133.3-59.1 133.3-131.8 0-35.2-15.2-68.3-40.1-93.2-25-25-58-38.7-93.2-38.7zm77.5 188.4c-3.3 9.3-19.1 17.7-26.7 18.8-12.6 1.9-22.4.9-47.5-9.9-39.7-17.2-65.7-57.2-67.7-59.8-2-2.6-16.2-21.5-16.2-41s10.2-29.1 13.9-33.1c3.6-4 7.9-5 10.6-5 2.6 0 5.3 0 7.6.1 2.4.1 5.7-.9 8.9 6.8 3.3 7.9 11.2 27.4 12.2 29.4s1.7 4.3.3 6.9c-7.6 15.2-15.7 14.6-11.6 21.6 15.3 26.3 30.6 35.4 53.9 47.1 4 2 6.3 1.7 8.6-1 2.3-2.6 9.9-11.6 12.5-15.5 2.6-4 5.3-3.3 8.9-2 3.6 1.3 23.1 10.9 27.1 12.9s6.6 3 7.6 4.6c.9 1.9.9 9.9-2.4 19.1zM400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM223.9 413.2c-26.6 0-52.7-6.7-75.8-19.3L64 416l22.5-82.2c-13.9-24-21.2-51.3-21.2-79.3C65.4 167.1 136.5 96 223.9 96c42.4 0 82.2 16.5 112.2 46.5 29.9 30 47.9 69.8 47.9 112.2 0 87.4-72.7 158.5-160.1 158.5z"></path></svg>
                      </div>
                      <div class="contact-info-text">
                        <h2>Whatsapp</h2>
                        <h3 className="Support">+91 8928301943</h3>
                        
                        <span>Milkvan</span> 
                       
                      </div>
                    </div>            
                  </div>
  </a>
  </div>
</div>
<div class="card01 col-md-4">
  
  <div class="card-body support">
<a href="tel:+91 8928301943">
<div class="contact-info">
                    <div class="contact-info-item">
                      <div class="contact-info-icon">
                      <svg className="email01" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm90.7 96.7c9.7-2.6 19.9 2.3 23.7 11.6l20 48c3.4 8.2 1 17.6-5.8 23.2L168 231.7c16.6 35.2 45.1 63.7 80.3 80.3l20.2-24.7c5.6-6.8 15-9.2 23.2-5.8l48 20c9.3 3.9 14.2 14 11.6 23.7l-12 44C336.9 378 329 384 320 384C196.3 384 96 283.7 96 160c0-9 6-16.9 14.7-19.3l44-12z"></path></svg>
                      </div>
                      <div class="contact-info-text">
                        <h2>Call Now</h2>
                        <h3 className="Support">+91 8928301943</h3>
                        
                        <span>Milkvan</span> 
                       
                      </div>
                    </div>            
                  </div>
</a>
  </div>
</div>





</div>
    </div>
    <section class="footer_get_touch_outer">
    <div class="container">
      <div class="footer_get_touch_inner row">
        <div class="col-md-6 get_form">
          <div class="get_form_inner">
            <div class="get_form_inner_text">
              <h3>Get In Touch</h3>
            </div>
            <form action="#">
              <div class="grid-50-50">
                <input type="text" placeholder="First Name"/>
                <input type="text" placeholder="Last Name"/>
                <input type="email" placeholder="Email"/>
                <input type="tel" placeholder="Phone/Skype"/>
              </div>
              <div class="grid-full">
                <textarea placeholder="Please input your message!t" cols="30" rows="10"></textarea>
                <input type="submit" value="Submit" disabled={true} /> 
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6 get_say_form">
          <h5>Visit Now</h5>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d549.4005410810586!2d75.67964650119794!3d26.957573780562164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ae3ccac10afe97b%3A0x1b0ce7bf7f0bb344!2sOrbot%20Softwares!5e0!3m2!1sen!2sin!4v1697442674974!5m2!1sen!2sin" style={{width:"100%" , height:"85%"}}></iframe>
          <ul class="get_say_social-icn">
            <li><a href="#"><i class="fa fa-facebook"></i></a></li>
            <li><a href="#"><i class="fa fa-instagram"></i></a></li>
            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
          </ul>          
        </div>        
      </div>
    </div>
  </section>
   </div>
  );
};

export default Support;
