import React, { useContext, useEffect, useState ,useRef} from "react";
import { Table, Modal, message, Space, Input ,Button} from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { PageTitle } from "../../PageTitle";
import axiosInstance from "../../axiosInstance";
import moment from 'moment';
import { UserContext } from "../../globalContext";
import Highlighter from "react-highlight-words";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";

const CustomerListScreen = () => {
 let navigate = useNavigate(); 
  const user =useContext(UserContext);
  const [list, setList] = useState([]);
  const [readCustomerObj, setReadCustomerObj] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, pickupService) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(pickupService);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (pickupService) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${pickupService}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, pickupService)
          }
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, pickupService)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(pickupService);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[pickupService]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === pickupService ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    let mounted = true;
    if (mounted) getCustomer();
    return () => (mounted = false);
  }, []);
  const columns = [
    
    {
      title: "Name",
      // dataIndex: "name",
      // key: "name",
      ...getColumnSearchProps("name"),
      render: (row) => {
        return row.name.length > 0 ? (
          <div>
            {<b>{row.name}</b>} <br/>
            <small>{row.email}</small>
            <br/>
            Mob. - <small>{row.mobile}</small>
          </div>
        ) : (
          <div> </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "registerDate",
      ...getColumnSearchProps("registerDate"),
      key: "registerDate",
      render:(registerDate)=>{
        return(
          <div>{moment(registerDate).format('DD-MMM-yyyy')}</div>
        )
      }
    },
  
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
          <>
            <EyeOutlined
              onClick={() => {
                readCustomer(row);
              }}
            />
            <EditOutlined
              style={{ marginLeft: 12 }}
              onClick={() => {
                modify(row);
              }}
            />
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removeCustomer(row);
              }}
            />
          </>
        );
      },
    },
  ];

  const getCustomer = () => {
    axiosInstance.get(`/customer/user/${user.companyId}`).then((response) => {
      setList(response.data.data);
    });
  };

  const readCustomer = (obj) => {
    axiosInstance.get(`/customer/${obj._id}`).then((response) => {
      setReadCustomerObj(response.data.data);
      
    });
   
    setIsModalOpen(true);
  };

  const modify = (obj) => {
     navigate(`/CustomerEditScreen/${obj._id}`, { replace: true });
  };

  const removeCustomer = (obj) => {
    Modal.confirm({
      title: "Do you want to remove this Member?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosInstance.put(`/customer/${obj._id}`, {isDeleted:true}).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            message.success("Record Deleted successfully");
            getCustomer();
            
          } else message.error("Something wrong. Please try again...!");
        });
      },
      onCancel() {},
    });
  };
const data ='Hello'
  const onAddClick = () => {
     navigate('/CustomerAddScreen');
   // window.location.href=`/CustomerAddScreen`, {list}
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Customer List";
    const headers =  [["Name", "Email","Mobile","Date"]]

    const data = list.map(elt=> [elt.name, elt.email,elt.mobile,elt.registerDate]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("CustomerList.pdf")
  }
  return (
  <div>
          <div className=" text-center main-heading bgcolor">
        <div  className="d-flex01">
        <h1>Customer List</h1>
      <div>
      <button className="btnmy02" onClick={() => onAddClick()}>
            + Add New{" "}
          </button>
        </div>
      </div>
      </div>
      <div className="customber-list-screen container">
  

      <div className="customber-list-screen-data">
        <Table rowKey="id" columns={columns} dataSource={list} />
      </div>
      <Modal
        title={readCustomerObj.name}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <ul className="list-group w-50">
        <li className="list-group-item"> Code : {readCustomerObj.customerCode}</li>

        <li className="list-group-item"> Name : {readCustomerObj.name}</li> 

          <li className="list-group-item"> Email : {readCustomerObj.email}</li>

          <li className="list-group-item">
            {" "}
            Mobile : {readCustomerObj.mobile}
          </li>
          <li className="list-group-item">
            {" "}
            Register Date : {moment(readCustomerObj.registerDate).format("DD-MM-yyyy")}
          </li>
          <li className="list-group-item">
            {" "}
            Reference By : {readCustomerObj.referenceBy}
          </li>
          <li className="list-group-item">
            {" "}
            Landmark : {readCustomerObj.landmark}
          </li>
          <li className="list-group-item">
            {" "}
            Address Line 1 : {readCustomerObj.addressLine1}
          </li>
          <li className="list-group-item">
            {" "}
            Address Line 2 : {readCustomerObj.addressLine2}
          </li>
          <li className="list-group-item"> City : {readCustomerObj.city}</li>
          <li className="list-group-item"> State: {readCustomerObj.state}</li>
          <li className="list-group-item">
            {" "}
            Country : {readCustomerObj.country}
          </li>
          <li className="list-group-item">
            {" "}
            Lattitude : {readCustomerObj.lattitude}
          </li>
          <li className="list-group-item">
            {" "}
            Longitude : {readCustomerObj.longitude}
          </li>
          <li className="list-group-item">
            {" "}
            MapLocation : {readCustomerObj.mapLocation}
          </li>
         
          <li className="list-group-item">
            {" "}
            Company Name : {readCustomerObj.companyId?.name}
            
          </li> 
          <li className="list-group-item">
            {" "}
            User Id : {readCustomerObj.userId}
          </li>
        </ul>
      </Modal>
      <div>
        <button className="btnmy033" onClick={() => exportPDF()}>Generate Report</button>
      </div>
    </div>
  </div>
  );
};

export default CustomerListScreen;
