import { Col, Form, Row, Button, Input, message, Table, Modal,Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { PageTitle } from "../../PageTitle";
import moment from "moment";

const { Option } = Select;
const Packages = () => {
    let defaultDate = new Date();
  const user=useContext(UserContext)
  const [packageValue, setPackageValue] = useState("");
  const [duration, setDuration] = useState("");
  const [PackageData, setPackageData] = useState([]);
  const [transactionDate, setTransactionDate] = useState(defaultDate);


  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);
  
  const pageLoad = () => {
    getPackages();
    onSetDate();
  }

  const onSetDate = () => {
    setTransactionDate(new Date());
  };
  const columns = [

    {
        title: "Packages Name",
        dataIndex: "duration",
        key: "duration",
      },
    {
        title: "Price",
        dataIndex: "packageValue",
        key: "packageValue",
      },    
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
   
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removePackage(row);
              }}
            />
        );
      },
    },
  ];

  const getPackages = () => {
    axiosInstance.get(`/packages`).then((response) => {
        setPackageData(response.data.data);
    });
  };
  const onSave = () => {
    const data = {
        packageValue: packageValue,
        duration: duration,
      transactionDate: new Date(transactionDate),
    };
    axiosInstance.post(`/packages`, data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
        setPackageValue("")
        setDuration("")
        getPackages();
      } else message.error("Something wrong. Please try again...!");
    });
  };

  const removePackage = (obj) => {
    Modal.confirm({
      title: "Do you want to remove this Member?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosInstance.delete(`/packages/${obj.id}`).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            message.success("Record Deleted successfully");
            getPackages();
          } else message.error("Something wrong. Please try again...!");
        });
      },
      onCancel() {},
    });
  };

  return (
   <div>
       <div>
      <div className="text-center main-heading">
      {/* <PageTitle title="Snf Screen">
        </PageTitle> */}
        <h1>Packages Screen</h1>
        </div>
      </div>
     <div className="master-snf-screen container">
    
      
      <div>
        <Form name="basic"
          
           >
          <div className="row mb-3 end">
          <div className="col-md-4 text">
          <label className="my">Price</label>
        <Form.Item colon={false} className="raj0101">
        <Input
                  placeholder="packageValue"
                  packageValue={packageValue}
                  onChange={(e) => setPackageValue(e.target.value)}
                />
              </Form.Item>
        </div>
            <div className="col-md-4 text">
              <Form.Item colon={false}  className="raj0101">
                <label className="my">Duration</label>
                <Input
                  placeholder="Duration"
                  duration={duration}
                  onChange={(e) => setDuration(e.target.value)}
                />
              </Form.Item>
            </div>
            
            <div className="col-md-4 text">
              <Button className="btnmy" onClick={onSave}>
                Save
              </Button>
            </div>
          </div>
          
        </Form>
      </div>
      <div>
        <Table columns={columns} dataSource={PackageData} />
      </div>
      
   
    </div>
   </div>
  );
};

export default Packages;
