import React, { useContext,useEffect,useState } from "react";
import { Row, Col } from "antd";
import { PageTitle } from "../PageTitle";
import { UserContext } from "../globalContext";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { Footers } from "../Footers";
const HomeScreen = (props) => {


  const user = useContext(UserContext);
  let navigate = useNavigate();
  useEffect(() => {
    
    let mounted = true;
    if (mounted) {

   
      if (user.userId == null) {
       window.location.reload(true);
      }

      
    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    // if(props.ppp===true){
    //   window.location.reload(true);
    //  props.ppp(false)
    // }
     
    
   
  }

  return (
    <div>
      <div>
        <Row>
          <Col span={10}>
            <PageTitle title="Dashboard"></PageTitle>
          </Col>
        </Row>
      </div>
      <Footer className="footerH">
      
          <div  span={10}>
           <a href="#/Feedback">Feedback </a>
          </div>
       
      </Footer>
    </div>
  );
};

export default HomeScreen;