import React, { useContext, useEffect, useState ,useRef} from "react";
import { Table, Modal, message, Space, Input ,Button} from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { PageTitle } from "../../PageTitle";
import axiosInstance from "../../axiosInstance";
import moment from 'moment';
import { UserContext } from "../../globalContext";
import Highlighter from "react-highlight-words";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";

const FeedbackListScreen = () => {
 let navigate = useNavigate(); 
  const user =useContext(UserContext);
  const [list, setList] = useState([]);
  const [readCustomerObj, setReadCustomerObj] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    let mounted = true;
    if (mounted) getCustomer();
    return () => (mounted = false);
  }, []);
  const columns = [
    
    {
      title: "Name",
      render: (row) => {
        return(
          <div>
            {<b>{row.memberId?.name}</b>} 
            <br/>
            Mob. - <small>{row.memberId?.mobile}</small>
          </div>
         
        );
      },
    },
    {
      title: "Feedback",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Date",
      dataIndex: "messageDate",
      key: "messageDate",
      render:(registerDate)=>{
        return(
          <div>{moment(registerDate).format('DD-MMM-yyyy')}</div>
        )
      }
    },
  
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
          <>
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removeCustomer(row);
              }}
            />
          </>
        );
      },
    },
  ];

  const getCustomer = () => {
    axiosInstance.get(`/feedback/`).then((response) => {
      setList(response.data.data);
      console.log("data",response.data.data[0].memberId);
    });
  };

  const removeCustomer = (obj) => {
    Modal.confirm({
      title: "Do you want to remove this Member?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosInstance.put(`/feedback/${obj._id}`, {isDeleted:true}).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            message.success("Record Deleted successfully");
            getCustomer();
            
          } else message.error("Something wrong. Please try again...!");
        });
      },
      onCancel() {},
    });
  };


 
  return (
  <div>
          <div className=" text-center main-heading bgcolor">
        <div  className="d-flex01">
        <h1>Feedback List</h1>
     
      </div>
      </div>
      <div className="customber-list-screen container">
  

      <div className="customber-list-screen-data">
        <Table rowKey="id" columns={columns} dataSource={list} />
      </div>
    
    
    </div>
  </div>
  );
};

export default FeedbackListScreen;
