import React, { useEffect, useState, useContext } from "react";
import { Col, Form, Row, Button, Input, message, Select,Modal } from "antd";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { PageTitle } from "../../PageTitle";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const PickupAddScreen = () => {
  let navigate = useNavigate();
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());
  defaultDate.setTime(defaultDate.getTime());
  const user = useContext(UserContext);
  const [routeList, setRouteList] = useState("");
  const [routeList1, setRouteList1] = useState("");
  const [customerList, setCustomerList] = useState("");
  const [vehicleList, setVehicleList] = useState("");
  const [milkType, setMilkType] = useState("");
  const [summery, setSummrey] = useState("");
  const [pickupShift, setPickupShift] = useState("Morning Shift");
  const [uom, setUom] = useState("Litre");
  const [quantity, setQuantity] = useState("");
  const [amount, setAmount] = useState("");
  const [rate, setRate] = useState("");
  const [transactionDate, setTransactionDate] = useState(defaultDate);
  const [fat, setFat] = useState("");
  const [snf, setSnf] = useState("");
  const [routeId, setRouteId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [companyId, setCompanyId] = useState('');
  const [code, setCode] = useState('');
  const [routeCheck, setRouteCheck] = useState(true);



  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);

  const pageLoad = () => {
    getRoute();
    getCustomer();
    getVehicle();
    routeIdCheak()
    setCompanyId(user.companyId);
  }
  const routeIdCheak =() => {
    if(user.role==="PEMPLOYEE"){
      if(user.route===1){
        Modal.confirm({
          title: "Plese contect your company and add a route with your id",
          okText: "Yes",
          okType: "danger",
         cancelText: "No",
          onOk() {
            handleLogout();
          },
          onCancel() { },
        });
       }
    }
     

  }
  const handleLogout = () => {
    localStorage.clear();
    
     window.location.href=`/`;
  }
  const getRoute = () => {
    axiosInstance.get(`/route/user/${user.companyId}`).then((response) => {
      setRouteList(response.data.data);
      if(response.data.data.length===0){
        setRouteId(user.companyId)

          setRouteCheck(false)
      }
     
    });
    if(user.route!==null&&user.route!==undefined){
    if(user.role==="PEMPLOYEE"){
       axiosInstance.get(`/route/${user.route}`).then((response) => {
         setRouteList1(response.data.data);
       })
     }}
  };
  const getCustomer = () => {
    axiosInstance.get(`/customer/user/${user.companyId}`).then((res) => {
      setCustomerList(res.data.data);
    });
  };
  const getVehicle = () => {
    axiosInstance.get(`/vehicle/user/${user.companyId}`).then((res) => {
      if (res.data.data.length === 0) {
        const data = {
          name: "Test",
          registrationNo: "Test",
          contactNumber: 1000110001,
          contactPerson: "Test",
          vehcleType: "650d460b18bc8e4f1c2310ef",
          userId: parseInt(user.userId),
          companyId: user.companyId,
        };
        axiosInstance.post(`/vehicle`, data).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            getVehicle();
          }
        })
      }
      setVehicleList(res.data.data);
    });
  };

  const onMilktypeChange = (e) => {
    setMilkType(e);
    if(e==="Cow"){
      setSnf(8.5)
    }
    else{
      setSnf(9.0)

    }
  }
  var data;

  const onSave = () => {
    
    if(user.role==="PEMPLOYEE"){
      
      data = {
        fat: fat,
        snf: snf,
        rate: rate,
        amount: amount,
        uom: uom,
        quantity: quantity,
        milkType: milkType,
        pickupShift: pickupShift,
        transactionDate: new Date(transactionDate),
        routeId:routeList1._id,
        customer: customerId,
        vehicle: vehicleId,
        userId: parseInt(user.userId),
        companyId: companyId,
      };
    }
    else{
      data = {
        fat: fat,
        snf: snf,
        rate: rate,
        amount: amount,
        uom: uom,
        quantity: quantity,
        milkType: milkType,
        pickupShift: pickupShift,
        transactionDate: new Date(transactionDate),
        routeId: routeId,
        customer: customerId,
        vehicle: vehicleId,
        userId: parseInt(user.userId),
        companyId: companyId,
      };
    }

   
    axiosInstance.post(`/pickup`, data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
        setSummrey(data);
        navigate('/PickupListScreen', { replace: true });
      } else message.error("Something wrong. Please try again...!");
    });
  };
  const onRouteChange = (e) => {
    setRouteId(e);
    setCustomerId("");
    axiosInstance.get(`/route/${e}`).then((response) => {
      setRouteList1(response.data.data);
    })
  };
  const onCustomeridChange = (e) => {
    setCustomerId(e);
    let cust =customerList.find(x => x._id.toString()===e.toString())
      setCode(cust.customerCode)
  };
  const onVehicleidChange = (e) => {
    setVehicleId(e);
  };

  const onFatChange = (e) => {
    setFat(e);
    axiosInstance
      .post(`fatsnfRateMatrix/rate`, {
        fat: e,
        snf: snf,
        companyId: (user.companyId),
      })
      .then((res) => {
        setRate(res.data.rate);
      });
  };
  const onQuantityChange = (e) => {
    setQuantity(e);

    var amount = parseFloat(e * rate).toFixed(2);
    setAmount(amount);
  };
  const onUOMChange = (e) => {
    setUom(e);
  };

  const onSetDate = (event) => {
    setTransactionDate(new Date(event.target.value));
  };
  const onShiftChange = (e) => {
    setPickupShift(e);
  };
  const onCodeChange = (e) => {
    setCode(e.target.value);
    setCustomerId("")
    if(e.target.value.length>2){
      let cust =customerList.find(x => x.customerCode.toString()===e.target.value.toString())
      setCustomerId(cust._id)
    }
   
   
  };


   
  return (
   <div>
     <div>
        <div className=" text-center main-heading">
          {/* <PageTitle title="Add Pickup">

          </PageTitle> */}
          <h1>Add Pickup</h1>
        </div>
      </div>
     <div className="pickp-add-screen container">

      <Form name="basic">
      <div className="row mb-3 end">

       {routeCheck===true &&<div className="col-md-12 text">
        {(user.role==="PCOMPANY" ||user.role==="Admin")&&

        <div>
          <label className="my">Route</label>
            <Form.Item colon={false} >
          <Select
            placeholder="Route"
            value={routeId}
            onChange={onRouteChange}
          >
            {routeList &&
              routeList.map((routeList) => (
                <Option key={routeList._id}>{routeList.name}</Option>
              ))}
          </Select>
        </Form.Item>
        </div>
        }
        </div>}
        <div className="col-md-4 text">
        <label className="my">Code</label>
              <Form.Item colon={false} >
                <Input type="integer"
                  placeholder="Code"
                  autoFocus={true}
                  value={code}
                  onChange={(onCodeChange)}
                />
              </Form.Item>
            </div>

      {routeCheck===true &&  <div className="col-md-4 text">
          <label className="my">Customer</label>
        <Form.Item colon={false} >
                <Select
                  placeholder="Customer"
                  value={customerId}
                  onChange={onCustomeridChange}
                >
                  {routeList1 &&
                    routeList1 && routeList1[0].customers &&routeList1[0].customers.map((el) => (
                      <Option key={el._id}>{el.name}</Option>
                    ))}
                </Select>
              </Form.Item>
        </div>}
        {routeCheck===false &&  <div className="col-md-4 text">
          <label className="my">Customer</label>
        <Form.Item colon={false} >
                <Select
                  placeholder="Customer"
                  value={customerId}
                  onChange={onCustomeridChange}
                >
                  {customerList &&
                    customerList && customerList.map((el) => (
                      <Option key={el._id}>{el.name}</Option>
                    ))}
                </Select>
              </Form.Item>
        </div>}

        <div className="col-md-4 text">
            <label className="my">Milk Type</label>
              <Form.Item colon={false} >
              <Select
                                    placeholder="Milk Type"
                                    value={milkType}
                                    onChange={onMilktypeChange}
                                >
                                    <Option value={"Cow"}>Cow</Option>
                                    <Option value={"Buff"}>Buff</Option>
                                    

                                </Select>
              </Form.Item>
            </div>

        <div className="col-md-4 text">
        <label className="my">Snf</label>
              <Form.Item colon={false} >
                <Input type="integer"
                  placeholder="Snf"
                  autoFocus={true}
                  value={snf}
                  onChange={(e) => setSnf(e.target.value)}
                />
              </Form.Item>
            </div>



           
            <div className="col-md-4 text">
            <label className="my">Fat</label>
              <Form.Item colon={false} >
                <Input type="integer"
                  min={1}
                  precision={2}
                  step={0.1}
                  max={2}
                  placeholder="Fat"
                  value={fat}
                  onChange={(e) => onFatChange(e.target.value)}
                />
              </Form.Item>
            </div>


            <div className="col-md-4 text">
            <label className="my">Rate</label>
              <Form.Item colon={false} disabled >
                <Input placeholder="Rate" disabled={true} value={rate} />
              </Form.Item>
            </div>


            <div className="col-md-4 text">
            <label className="my">Quantity</label>
              <Form.Item colon={false} >
                <Input
                  type="integer"
                  placeholder="Quantity"
                  value={quantity}
                  onChange={(e) => onQuantityChange(e.target.value)}
                />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <label className="my">Amount</label>
              <Form.Item colon={false} >
                <Input placeholder="Amount" disabled={true} value={amount} />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <label className="my">UOM</label>
              <Form.Item colon={false} >
                <Select placeholder="UOM" value={uom} onChange={onUOMChange}>
                  <Option key={"Kg"}>Kg</Option>
                  <Option key={"Litre"}>Litre</Option>
                </Select>
              </Form.Item>
            </div>

          
            <div className="col-md-4 text">
            <label className="my">Pickup Shift</label>
              <Form.Item colon={false} >
                <Select
                  placeholder="Pickup Shift"
                  value={pickupShift}
                  onChange={onShiftChange}
                >
                  <Option key={"Morning Shift"}>Morning Shift</Option>
                  <Option key={"Evening Shift"}>Evening Shift</Option>
                </Select>
              </Form.Item>
            </div>


            <div className="col-md-4 text">
            <label className="my">Vehicle</label>
              <Form.Item colon={false}  className="raj0101">
                <Select
                  placeholder="Vehicle"
                  value={vehicleId}
                  onChange={onVehicleidChange}
                >
                  {vehicleList &&
                    vehicleList.map((vehicleList) => (
                      <Option key={vehicleList._id}>{vehicleList.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <label className="my">Date</label>
              <Form.Item colon={false}  className="raj0101">
                <Input
                  disabled={true}
                  value={transactionDate.toLocaleDateString("CA")}
                  onChange={onSetDate}
                />
              </Form.Item>
            </div>

            <div className="col-md-4 text">
            <div>
              <Button className="btnmy" onClick={onSave}>
                Save
              </Button>
            </div>
          </div>





      </div>
      </Form>
     

    </div>
   </div>
  );
};

export default PickupAddScreen;
