import { Col, Form, Row, Input,Select, Button, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { PageTitle } from "../../PageTitle";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const EmployeeAddSecreen = () => {
  const user = useContext(UserContext);

 let navigate = useNavigate(); 
  const [RoleList, setRoleList] = useState();
  const [Role, setRole] = useState();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [password, setPassword] = useState();
  const [address, setAddress] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) getRole();
    return () => (mounted = false);
  }, []);
  const getRole = () => {
    axiosInstance.get("/role").then((response) => {
      setRoleList(response.data.data);
if(user.role==="PCOMPANY"){
  let droleObj = response.data.data.find(x => x.name === 'PEMPLOYEE');
  setRole(droleObj._id);
  
}
else{
  if(user.role==="SCOMPANY"){
    let dslectRoleobj = response.data.data.find(x => x.name === 'SEMPLOYEE');
    setRole(dslectRoleobj._id);
  }
}
});
};
  const onAdd = () => {
    const data = {
      email: email,
      name: name,
      mobile: mobile,
      password: password,
      address: address,
      role:Role,
      companyId: user.companyId ,

    };
    axiosInstance.post("/member", data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
       navigate('/MemberListScreen', { replace: true });
      } else message.error("Something wrong. Please try again...!");
    });
  };

  return (
    <div>
    <div className=" text-center main-heading">
      {/* <PageTitle title=""></PageTitle> */}
      <h1>Add Employee</h1>
    </div>
 <div className="customber-add-screen container">

   <Form name="basic">
  <div  className="row mb-3 end">
    <div className="col-md-4 text">
    <div>
      <label className="my">Full Name</label>
          <Form.Item
            colon={false}
            
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your Name!",
              },
            ]}
          >
            <Input
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus={true}
            />
          </Form.Item>
        </div>
    </div>


    <div className="col-md-4 text">
    <label className="my">Email</label>
    <Form.Item

            colon={false}
         
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input valid Email!",
              },
            ]}
          >
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
    </div>

    <div className="col-md-4 text">
    <label className="my">Mobile</label>
    <Form.Item
            colon={false}
           
            name="mobile"
            rules={[
              {
                required: true,
                message: "Please input your Mobile!",
              },
              {
              max: "10",
              message: "Maximum 10 Digits",
            },
            ]}
          >
            <Input
            type="number"
              placeholder="Mobile"
              value={mobile}
              maxLength={10}
              minLength={10}
              onChange={(e) => setMobile(e.target.value)}
            />
          </Form.Item>
    </div>
    <div className="col-md-4 text">
    <div>
      <label className="my">Address</label>
          <Form.Item
            colon={false}
            
            name="address"
            rules={[
              {
                required: true,
                message: "Please input your address!",
              },
            ]}
          >
            <Input
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              autoFocus={true}
            />
          </Form.Item>
        </div>
    </div>

    
        <div className="col-md-4 text">
        <label className="my">Password</label>
            <Form.Item
              colon={false}
             
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}  
            >
              <Input.Password
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
            </Form.Item>
          </div>
          <div className="col-md-4 text ">
              
              <Button
                className="btnmy"
                htmlType="submit"
                onClick={() => onAdd()}
              >
                Add
              </Button>
            </div>


  </div>
  </Form>
 
</div>
</div>
  );
};

export default EmployeeAddSecreen;
