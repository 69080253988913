import { Col, Form, Row, Button, Input, message, Table, Modal } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { PageTitle } from "../../PageTitle";

const FatScreen = () => {
  const user = useContext(UserContext);
  const [value, setValue] = useState("");
  const [fatData, setFatData] = useState([]);
  const [readFatObj, setReadFatObj] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyId, setCompanyId] = useState('');


  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);
  
  const pageLoad = () => {
    getFat();
    setCompanyId(user.companyId );
  }
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Company Name",
      render: (row) => {
        return(
          <>
          {row.companyId?.name}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
          <>
            <EyeOutlined
              onClick={() => {
                readFat(row);
              }}
            />
            <EditOutlined
              style={{ marginLeft: 12 }}
              // onClick={() => {
              //   modify(row);
              // }}
            />
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removeFat(row);
              }}
            />
          </>
        );
      },
    },
  ];

  const getFat = () => {
    axiosInstance.get(`/fat`).then((response) => {
      setFatData(response.data.data);
    });
  };
  const readFat = (obj) => {
    axiosInstance.get(`/fat/${obj.id}`).then((response) => {
      setReadFatObj(response.data.data);
    });
    setIsModalOpen(true);
  };
  const onSave = () => {
    const data = {
      value: value,
      companyId: companyId,
      userId: parseInt(user.userId),
    };
    axiosInstance.post(`/fat`, data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
      } else message.error("Something wrong. Please try again...!");
    });
  };
  const removeFat = (obj) => {
    Modal.confirm({
      title: "Do you want to remove this Member?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosInstance.delete(`/fat/${obj.id}`).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            message.success("Record Deleted successfully");
          } else message.error("Something wrong. Please try again...!");
        });
      },
      onCancel() {},
    });
  };
  return (
 <div>
    <div>
      <div className="text-center main-heading">
      {/* <PageTitle title="Fat Screen">
        </PageTitle> */}
       <h1>Fat Screen</h1>
        </div>
      </div>
  <div>
  <div className="fat-sereen container">
    

      <div>
        <Form
          name="basic">
         
          <div className="row mb-3 end">
            <div className="col-md-4 text">
              <Form.Item colon={false}  className="raj0101">
                <label  className="my">Fat Value</label>
                <Input
               
                  placeholder="Fat Value"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="col-md-4 text">
              <Button className="btnmy"  onClick={onSave}>
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
      <div>
        <Table columns={columns} dataSource={fatData} />
      </div>
      <Modal
        title="Fat-List"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <ul className="list-group w-50">
          <li className="list-group-item"> ID : {readFatObj.id}</li>
          <li className="list-group-item"> Value : {readFatObj.value}</li>
          <li className="list-group-item">
            {" "}
            Company Name : {readFatObj.companyId?.name}
          </li>
          <li className="list-group-item"> User Id : {readFatObj.userId}</li>
        </ul>
      </Modal>
    </div>
  </div>
 </div>
  );
};

export default FatScreen;
