import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Modal,
  message,
  Form,
  Row,
  Col,
  Button,
  DatePicker,
  Space,
  Select,
} from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import { PageTitle } from "../../PageTitle";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Option } from "antd/lib/mentions";

// import { useNavigate } from "react-router-dom";
// navigate('AddBank', { replace: true });
// let navigate = useNavigate(); 


const AllSalesListScreen = () => {
  let navigate = useNavigate(); 
  const user = useContext(UserContext);
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  let defaultOldDate = new Date(); 
  defaultOldDate.setDate(defaultOldDate.getDate()-30);

  const [pickupService, setPickupService] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [readPickupObj, setReadPickupObj] = useState({});
  const [fromDate, setFromDate] = useState(defaultOldDate);
  const [toDate, setToDate] = useState(defaultDate);
  const [companyId, setCompanyId] = useState('');
  const [company, setCompany] = useState('');
  
  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);
  
  const pageLoad = () => {
    getPickup();
    getCompany();
  }

  const columns1 = [
    {
      title: "Customer",
      //dataIndex: "customers",
      //key: "customers",
      render: (row) => {
        return row.customer ? (
          <div>

          { row&& <b>{row.customer.name}</b>}<br/>
          MilkType - <label className="pickup-list-label">{row.milkType}</label>
            Rate - <small>{row.rate}</small><br/>
            Quantity - <label className="pickup-list-label">{row.quantity}</label>
            Amount - <label>{row.amount}</label>
            </div>
        ) : (
          <div> </div>
        );
      },

    },
    {
      title: "Date",
      //dataIndex: "dates",
      //key: "dates",
      render: (row) => {
        return(
          <>
          {row && <div>{moment(row.transactionDate).format("DD-MM-yyyy")}</div>}
          </>
        );
      },

    },
  
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
          <div className="icon-list-pic">
            <EyeOutlined
              onClick={() => {
                readPickup(row);
              }}
            />
            {/* <EditOutlined
              style={{ marginLeft: 12 }}
              onClick={() => {
                modifyPickup(row);
              }}
            /> */}
            {/* <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removePickup(row);
              }}
            /> */}
          </div>
        );
      },
    },
  ];

  const getPickup = () => {
   
      axiosInstance.get(`/salemilk`).then((res) => {
        setPickupService(res.data.data);
      });   
  };
  const getCompany = () => {
    axiosInstance.get(`/company`).then((res) => {
        setCompany(res.data.data);
        console.log("pickup", res.data.data)
    });
};

  const readPickup = (obj) => {
    // axiosInstance.get(`/pickup/${obj.id}`).then((response) => {
    //   setReadPickupObj(response.data.data);
    // });
    setReadPickupObj(obj)
    setIsModalOpen(true);
  };

  // const modifyPickup = (obj) => {
// navigate('PickupEditScreen/${obj.id}', { replace: true });
  // };

  const removePickup = (obj) => {
    Modal.confirm({
      title: "Do you want to remove this Member?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosInstance.delete(`/pickup/${obj.id}`).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            message.success("Record Deleted successfully");
          } else message.error("Something wrong. Please try again...!");
        });
      },
      onCancel() {},
    });
  };
  const onAddClick = () => {
   navigate('/AddSales', { replace: true });
  };
  const onSummery = () => {
    // navigate('/collactionSummery', { replace: true });
  }
  const onGo = () => {
    axiosInstance
      .post(`/salemilk/user/${user.companyId}`, {
        fromDate: fromDate,
        toDate: toDate,
      })
      .then((res) => {
        if (res.data && res.data.responseCode === -1) {
          message.error("Failed! please contact administor.");
        } else if (res.data && res.data.responseCode === 1) {
          message.success("Filter applied successfully");
          setPickupService(res.data.data);
        } else message.error("Something wrong. Please try again...!");
      });
  };
  const onFromChange = (date, dateString) => {
    setFromDate(date, dateString);
  };
  const onToChange = (date, dateString) => {
    setToDate(date, dateString);
  };

  const dateFormat = "DD/MM/YYYY";
  const onRouteChange = (e) => {
    setCompanyId(e)
    axiosInstance.get(`/salemilk/user/${e}`).then((res) => {
        setPickupService(res.data.data);
    })
};
  return (
    
    <div>

<div>
                <div className=" text-center main-heading">
                   
                    <h1>Sales List</h1>
                </div>
            </div>
    
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <label className="col-md-2 Company">Plese Select Company </label>
                    <div className="col-md-4">
                    <Form.Item colon={false}
                     className="rajbottom" >
                        <Select
                            placeholder="Route"
                            value={company}
                            onChange={onRouteChange}
                            className="rajbottom0"
                           
                        >
                            {company &&
                                company.map((company) => (
                                    <Option key={company._id}>{company.name}</Option>

                                ))}
                        </Select>

                    </Form.Item>
                    </div>
                </div>
            </div>
    
           <div className="pickup-list-screen container">
    
    
    
         <Form name="basic">
         {(user.role === 'Company'||user.role === 'Admin' )&&
    
         <div className="row mb-3 end">
              <div className="col-md-4 text">
                <label className="my">From</label>
              <Form.Item colon={false} >
                  <Space direction="vertical">
                    <DatePicker
                      defaultValue={moment(defaultOldDate, dateFormat)}
                      format={dateFormat}
                      onChange={onFromChange}
                    />
                  </Space>
                </Form.Item>
              </div>
    
              <div className="col-md-4 text">
              <label className="my">To</label>
              <Form.Item colon={false} >
                  <Space direction="vertical">
                    <DatePicker
                      defaultValue={moment(defaultDate, dateFormat)}
                      format={dateFormat}
                      onChange={onToChange}
                    />
                  </Space>
                </Form.Item>
              </div>
    
              <div className="col-md-4 text">
             
              <Form.Item colon={false}>
                  <Button className="btnmy" onClick={onGo}>
                    Go
                  </Button>
                </Form.Item>
              </div>
    
            </div>
          }
         </Form>
    
    
          <div>
    
    
           
        
    
            {/* <Form name="basic">
             <div>
                <div>
    
                </div>
                <Col lg={4} md={6} sm={8} xs={10}>
              
                </Col>
                <Col lg={4} md={6} sm={8} xs={4}>
          
                </Col>
              </div>}
            </Form> */}
          </div>
          <div  style={{overflow:"auto"}}>
            {pickupService.length > 0 && <Table key="id" columns={columns1} dataSource={pickupService} />}
          </div>
          <Modal
            title="Pickup Details"
            open={isModalOpen}
            onOk={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}
          >
            <ul className="list-group w-50" >
                {/* <li className="list-group-item"> ID : {readPickupObj.id}</li>
                <li className="list-group-item"> User ID : {readPickupObj.userId}</li> */}
              <li className="list-group-item">
                Company Name : {readPickupObj.companyId?.name}
              </li>
              <li className="list-group-item">
                {" "}
                Route Name : {readPickupObj.routeId?.name}
              </li>
              <li className="list-group-item">
                {" "}
                Vehicle Name : {readPickupObj.vehicle?.name}
              </li>
              <li className="list-group-item">
                {" "}
                Customer Name : {readPickupObj.customer?.name}
              </li>
              <li className="list-group-item"> Snf : {readPickupObj.snf}</li>
              <li className="list-group-item"> Fat : {readPickupObj.fat}</li>
              <li className="list-group-item"> Rate : {readPickupObj.rate}</li>
              <li className="list-group-item">
                {" "}
                Quantity : {readPickupObj.quantity}
              </li>
              <li className="list-group-item"> Amount : {readPickupObj.amount}</li>
              <li className="list-group-item"> UOM : {readPickupObj.uom}</li>
              <li className="list-group-item">
                {" "}
                Milk Type : {readPickupObj.milkType}
              </li>
              <li className="list-group-item">
                {" "}
                Pickup Shift : {readPickupObj.pickupShift}
              </li>
    
              <li className="list-group-item">
                {" "}
                Date : {moment(readPickupObj.transactionDate).format("DD-MM-yyyy")}
              </li>
            </ul>
          </Modal>
                <Col lg={4} md={6} sm={8} xs={4}>
                {(user.role === 'Company'||user.role === 'Admin' )&&<Form.Item colon={false}>
                  <Button  onClick={onSummery} className="btnmy">
                    Viwe Summery
                  </Button>
                </Form.Item>}
                </Col>
        </div>
       </div>
  );
};

export default AllSalesListScreen;
