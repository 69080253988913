import React, { useState, useEffect, useContext } from "react";
import {  Form, Input, Button, Select, message } from "antd";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "./../../globalContext";
import { PageTitle } from "../../PageTitle";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
const { Option } = Select;
const AddFeedback = () => {
 let navigate = useNavigate(); 
  const user = useContext(UserContext);
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

 
  const [message1, setMessage1] = useState();
  
  const [registerDate, setRegisterDate] = useState(defaultDate);
 
  const [memberId, setMemberId] = useState('');

  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);

  const pageLoad = () => {
    setMemberId(user.memberId);
  }
 
  

  const onAdd = () => {
    const data = {
        message:message1,
        messageDate: registerDate,
        memberId: memberId,
    
    };
console.log("data",data)

    axiosInstance.post("/feedback", data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
         navigate('/HomeScreen', { replace: true });
      } else message.error("Something wrong. Please try again...!");
    });
  };
  const onSetDate = (event) => {
    setRegisterDate(new Date(event.target.value));
  };

  return (
   <div>
        <div className=" text-center main-heading">
          {/* <PageTitle title=""></PageTitle> */}
          <h1>Add Feedback</h1>
        </div>
     <div className="customber-add-screen container">
       <Form name="basic">
      <div  className="row mb-3 end">
        <div className="col-md-4 text">
        <div>
          <label className="my">Feedback</label>
              <Form.Item
                colon={false}
                
                name="message1"
                rules={[
                  {
                    required: true,
                    message: "Please input your message!",
                  },
                ]}
              >
                {/* <Input
                  placeholder="Message"
                  value={message1}
                  onChange={(e) => setMessage1(e.target.value)}
                  autoFocus={true}
                /> */}
                <TextArea rows={4} placeholder="Please give us your feedback" maxLength={300} 
                    value={message1}
                    onChange={(e) => setMessage1(e.target.value)}
                    autoFocus={true}
                />
              </Form.Item>
            </div>
        </div>
        {/* <div className="col-md-4 text">
        <label className="my">Registration Date</label>
              <Form.Item colon={false} >
                <Input
                  disabled={true}
                  value={registerDate.toLocaleDateString("CA")}
                  onChange={onSetDate}
                />
              </Form.Item>
            </div> */}
            <div className="col-md-4 text ">
              
              <Button
                className="btnmy"
                htmlType="submit"
                onClick={() => onAdd()}
              >
                Add
              </Button>
            </div>
      </div>
      </Form>
     
      
    </div>
   </div>
  );
};

export default AddFeedback;
