import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Button,
  Input,
  message,
  Table,
  Modal,
  Select,
} from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { PageTitle } from "../../PageTitle";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const VehicleScreen = () => {
  let navigate = useNavigate();
  const user= useContext(UserContext);
  const [name, setName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [vehcleType, setVehcleType] = useState("");
  const [registrationNo, setRegistrationNo] = useState("");
  const [vehicleData, setVehicleData] = useState([]);
  const [readVehicleObj, setReadVehicleObj] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vechicleTypeList, setVechicleTypeList] = useState();
  const [companyId, setCompanyId] = useState('');


  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getVehicle();
    getVehicleType();
    setCompanyId(user.companyId );
  }

  const getVehicleType = () => {
    axiosInstance.get(`/vehicleType`).then((response) => {
      setVechicleTypeList(response.data.data);
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Registration No",
      dataIndex: "registrationNo",
      key: "registrationNo",
    },
    {
      title: "Company Name",
      render: (row) => {
        return(
          <>
          {row.companyId?.name}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
          <>
            <EyeOutlined
              onClick={() => {
                readVehicleType(row);
              }}
            />
            <EditOutlined
              style={{ marginLeft: 12 }}
              onClick={() => {
                modify(row);
              }}
            />
            <DeleteOutlined
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removeVehicleType(row);
              }}
            />
          </>
        );
      },
    },
  ];

  const modify = (obj) => {
    navigate(`/VehicleEditScreen/${obj._id}`, { replace: true });
  };
  const getVehicle = () => {
    axiosInstance.get(`/vehicle/user/${user.companyId}`).then((response) => {
      setVehicleData(response.data.data);
    });
  };
  const readVehicleType = (obj) => {
    axiosInstance.get(`/vehicle/${obj._id}`).then((response) => {
      setReadVehicleObj(response.data.data);
    });
    setIsModalOpen(true);
  };

  const onVehicletypeChange = (e) => {
    setVehcleType(e);
  };

  const onSave = () => {
    const data = {
      name: name,
      registrationNo: registrationNo,
      contactNumber: contactNumber,
      contactPerson: contactPerson,
      vehcleType: vehcleType,
      userId:parseInt(user.userId),
      companyId:companyId,
    };
    axiosInstance.post(`/vehicle`, data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
        getVehicle();
        setName("")
        setRegistrationNo("")
        setContactNumber("")
        setContactPerson("")
        setVehcleType("")
      } else message.error("Something wrong. Please try again...!");
    });
  };

  const removeVehicleType = (obj) => {
    Modal.confirm({
      title: "Do you want to remove this Member?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosInstance.delete(`/vehicle/${obj.id}`).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            message.success("Record Deleted successfully");
          } else message.error("Something wrong. Please try again...!");
        });
      },
      onCancel() {},
    });
  };

  return (
  <div>
      <div>
      <div className="text-center main-heading">
      {/* <PageTitle title="Vehicle">
        </PageTitle> */}
        <h1>Vehicle</h1>
        </div>
      </div>
      <div className="VehicleScreen container">
   

      <div>
        <Form name="basic" >
        <div className="row mb-3 end">
          <div className="col-md-4 text">
            <label className="my">Name</label>
            <Form.Item colon={false} >
              <Input
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="col-md-4 text">
            <label className="my">Registration No</label>
            <Form.Item colon={false}>
              <Input
                placeholder="Registration No"
                value={registrationNo}
                onChange={(e) => setRegistrationNo(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="col-md-4 text">
            <label className="my">Contact Person</label>
            <Form.Item colon={false}>
              <Input
                placeholder="Contact Person"
                value={contactPerson}
                onChange={(e) => setContactPerson(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="col-md-4 text">
            <label className="my">Contact Number</label>
            <Form.Item colon={false} className="raj0101"
             rules={[
                  {
                    required: true,
                    message: "Please input your Mobile!",
                  },
                  {
                  max: "10",
                  message: "Maximum 10 Digits",
                },
                ]}>
              <Input
              
                placeholder="Contact Number"
                value={contactNumber}
                maxLength={10}
                  minLength={10}
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="col-md-4 text">
            <label className="my">Vehicle Type</label>
            <Form.Item colon={false} className="raj0101">
              <Select
                placeholder="Vehicle Type"
                value={vehcleType}
                onChange={onVehicletypeChange}
              >
                {vechicleTypeList &&
                  vechicleTypeList.map((vechicleTypeList) => (
                    <Option key={vechicleTypeList.name}>
                      {vechicleTypeList.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-md-4 text">
          <div>
            <Button className="btnmy" onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
        </div>
       
        </Form>
      </div>
      <div>
        <Table columns={columns} dataSource={vehicleData} />
      </div>
      <Modal
        title="Vehicle Details"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <ul className="list-group w-50">
          <li className="list-group-item"> ID : {readVehicleObj.id}</li>
          <li className="list-group-item"> Name: {readVehicleObj.name}</li>
          <li className="list-group-item">
            {" "}
            Registration No: {readVehicleObj.registrationNo}
          </li>
          <li className="list-group-item">
            {" "}
            Person: {readVehicleObj.contactPerson}
          </li>
          <li className="list-group-item">
            {" "}
            Number: {readVehicleObj.contactNumber}
          </li>
          <li className="list-group-item">
            {" "}
            Vehicle Type: {readVehicleObj.vehcleType}
          </li>
          <li className="list-group-item">
            {" "}
            Company Id : {readVehicleObj.companyId?.name}
          </li>
          <li className="list-group-item">
            {" "}
            User Id : {readVehicleObj.userId}
          </li>
         
        </ul>
      </Modal>
    </div>
  </div>
  );
};

export default VehicleScreen;
